import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#acd8f5',
      light: '#e8f0fe'
    },
    secondary: {
      main: '#ffcece'
    },
    background: {
      default: '#d1f9d1'
    }
  },
  typography: {
    fontFamily: ['Chewy', 'Nanum Pen Script'].join(','),
    h4: {
      fontFamily: 'Nanum Pen Script',
      fontSize: 35
    },
    h5: {
      fontFamily: 'Nanum Pen Script',
      fontSize: 25
    },
    h6: {
      fontFamily: 'Nanum Pen Script',
      fontSize: 15
    },
    body1: {
      fontFamily: 'Nanum Pen Script'
      // fontSize: 40
    },
    body2: {
      fontFamily: 'Nanum Pen Script'
      // fontSize: 25
    }
  }
})

export default theme
