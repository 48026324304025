import * as React from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { Delete, DragHandle, Visibility, VisibilityOff } from '@mui/icons-material'

import styles from './styles'
import ToolTip from 'Components/Tooltip'
import IconButton from 'Components/IconButton'
import ConfirmDialog from 'Components/ConfirmDialog'
import { HeaderV2Props } from 'Types'

const FormHeaderV2: React.FC<HeaderV2Props> = ({
  title,
  index,
  remove,
  variant,
  toolTip,
  disableDrag = false,
  listeners,
  attributes,
  hide,
  setHide
}) => {
  const [open, setOpen] = React.useState(false)

  const dialogTitle = 'Remove Form Component'
  const dialogDescription = 'Are you sure you want to fully delete this Component?'
  const confirmButtonText = 'Remove Form component.'

  return (
    <Grid item xs={12} sx={styles.header}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant={variant || 'h5'}>{title}</Typography>
        {toolTip ? <ToolTip text={toolTip} /> : undefined}
      </Box>

      <div hidden={disableDrag}>
        <IconButton
          tooltip={!hide ? 'Hide Section' : 'Show Section'}
          onClick={() =>
            setHide && setHide(prev => ({ state: !prev.state, prevState: prev.state }))
          }
        >
          {hide ? <VisibilityOff /> : <Visibility />}
        </IconButton>
        <IconButton tooltip='Delete Section' onClick={() => setOpen(true)}>
          <Delete />
        </IconButton>
        <IconButton tooltip='Drag and Move Section' {...listeners} {...attributes}>
          <DragHandle sx={{ cursor: 'move' }} />
        </IconButton>
      </div>
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        loading={false}
        onConfirm={() => remove(index)}
        title={dialogTitle}
        description={dialogDescription}
        confirmButtonText={confirmButtonText}
      />
    </Grid>
  )
}

export default FormHeaderV2
