import * as React from 'react'
import { Grid, Typography } from '@mui/material'

import RsvpCounter from 'Components/RSVPCounter'

const styles = {
  container: {
    marginTop: '2em',
    justifyContent: 'center'
  }
}

type Props = {}

const Dashboard: React.FC<Props> = () => {
  return (
    <Grid container spacing={2} sx={styles.container}>
      <Grid item xs={12}>
        <Typography variant='h2'> Dashboard Coming Soon </Typography>
      </Grid>
      <RsvpCounter />
    </Grid>
  )
}

export default Dashboard
