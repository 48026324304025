import React, { useState } from 'react'
import { Button, IconButton, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import './file-upload.css'

import { useAxios } from 'Hooks'
import { UploadProps } from 'Types'

const FileUpload: React.FC<UploadProps> = ({
  multiple,
  label,
  updateFilesCb,
  maxFileSize,
  value,
  error,
  ...boxProps
}) => {
  // Todo need to be able to pass files into here for viewing
  const [files, setFiles] = useState<string | string[]>()
  const { get } = useAxios()

  React.useEffect(() => {
    // console.log({ value })
    if (files) {
      if (Array.isArray(files)) updateFilesCb(files)
      else if (multiple) updateFilesCb([files])
      else updateFilesCb(files)
    } else if (!files && value) {
      console.log('setting files')
      setFiles(value)
    } else if (!files) {
      updateFilesCb('')
    }
  }, [files, updateFilesCb, multiple, value, get])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files).map(file => URL.createObjectURL(file))
      if (!multiple) return setFiles(newFiles[0])
      return setFiles(prev => {
        if (Array.isArray(prev)) return [...prev, ...newFiles]
        else if (prev) return [prev, ...newFiles]
        else return [...newFiles]
      })
    }
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files) {
      const newFiles = Array.from(e.dataTransfer.files).map(file => URL.createObjectURL(file))
      if (!multiple) return setFiles(newFiles[0])
      return setFiles(prev => {
        if (Array.isArray(prev)) return [...prev, ...newFiles]
        else if (prev) return [prev, ...newFiles]
        else return [...newFiles]
      })
    }
  }

  const handleMultipleClear = (index: number) => {
    if (!Array.isArray(files)) return
    const newFiles = [...files]
    newFiles.splice(index, 1)
    if (newFiles.length) return setFiles(newFiles)
    else return setFiles(undefined)
  }
  const handleClearClick = () => {
    setFiles(undefined)
    updateFilesCb('')
  }

  return (
    <Box
      className='dropzone'
      sx={error ? { border: '2px solid red' } : undefined}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      {...boxProps}
    >
      {!files && (
        <>
          <p>Drag and drop {label} Image here</p>
          <Button variant='contained' component='label'>
            or click here to upload
            <input
              hidden
              accept='image/*'
              multiple={multiple}
              type='file'
              onChange={handleFileChange}
            />
          </Button>
        </>
      )}
      {Array.isArray(files) ? (
        <div>
          <h4>File preview:</h4>
          {files.map((file, index) => (
            <div key={file} style={{ position: 'relative' }}>
              <img src={file} alt={file} />
              <IconButton
                onClick={() => handleMultipleClear(index)}
                sx={{ position: 'absolute', top: '10px', right: '10px' }}
              >
                <Close />
              </IconButton>
            </div>
          ))}
        </div>
      ) : (
        <div>
          {files && (
            <>
              <h4>File preview:</h4>
              <div style={{ position: 'relative' }}>
                <img src={files} alt={files} />
                <IconButton
                  onClick={() => handleClearClick()}
                  sx={{ position: 'absolute', top: '10px' }}
                >
                  <Close />
                </IconButton>
              </div>
            </>
          )}
        </div>
      )}
    </Box>
  )
}

export default FileUpload
