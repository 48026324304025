import * as React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useRecoilState } from 'recoil'
import { useFormContext } from 'react-hook-form' 

import { BasicUpload, CheckBox, TextField } from 'Components/Form'
import { EventCard, EventTicket as Eventticket } from 'Components/EventTicket'
import { EventFormProps, EventTicket } from 'Types'
import styles from './styles'
import FormContainer from './FormContainer'
import { eventTicketFormState } from 'State'

const EventTicketForm: React.FC<EventFormProps> = ({ control, index, ...rest }) => {
  const [formState, setFormState] = useRecoilState(eventTicketFormState)
  const { getValues } = useFormContext()
  const [formValues, setFormValues] = React.useState<EventTicket>({
    ...getValues(`sections.${index}`)
  })

  React.useEffect(() => {
    if (!formState) setFormState(true)
    return () => {
      if (formState) setFormState(false)
    }
  }, [formState, setFormState])

  function updateValues(value: string) {
    setFormValues({ ...getValues(`sections.${index}`) })
    return value
  }

  const tooltip = `The event ticket form is what controls how the animated ticket works in the bottom of the screen, the background image is repeated on either side so generally want an image that repeats into itself nicely,
                  the left side content is rotated 90 degrees and is the rip of portion of a ticket stub, right portion looks like normal. `

  return (
    <FormContainer
      index={index}
      toolTip={tooltip}
      gridProps={{ display: 'flex', justifyContent: 'center' }}
      {...rest}
    >
      <Grid item xs={12} md={4}>
        <BasicUpload
          control={control}
          fieldName={`sections.${index}.bg`}
          multiple={false}
          label={'Background'}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          control={control}
          fieldName={`sections.${index}.leftContent.header`}
          label={'Left Content Header'}
          disableGrid
          onChangeFilter={val => updateValues(val)}
          sx={styles.item}
        />
        <TextField
          control={control}
          fieldName={`sections.${index}.leftContent.sub`}
          label={'Left Content Sub Header'}
          disableGrid
          onChangeFilter={val => updateValues(val)}
          sx={styles.item}
        />
        <CheckBox
          control={control}
          fieldName={`sections.${index}.leftContent.barcode`}
          label={'Show Barcode?'}
          defaultChecked={true}
          disableGrid
          sx={styles.item}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          control={control}
          fieldName={`sections.${index}.rightContent.firstHeader`}
          label={'Right Content First Header'}
          disableGrid
          onChangeFilter={val => updateValues(val)}
          sx={styles.item}
        />
        <TextField
          control={control}
          fieldName={`sections.${index}.rightContent.secondHeader`}
          label={'Right Content Second Header'}
          disableGrid
          onChangeFilter={val => updateValues(val)}
          sx={styles.item}
        />
        <TextField
          control={control}
          fieldName={`sections.${index}.rightContent.firstSub`}
          label={'Right Content First Sub Header'}
          disableGrid
          onChangeFilter={val => updateValues(val)}
          sx={styles.item}
        />
        <TextField
          control={control}
          fieldName={`sections.${index}.rightContent.secondSub`}
          label={'Right Content Second Sub Header'}
          disableGrid
          onChangeFilter={val => updateValues(val)}
          sx={styles.item}
        />
      </Grid>
      <Grid item xs={12} md={7} lg={6}>
        <Typography variant='h5'>Desktop Preview</Typography>
        <Eventticket {...formValues} cb={() => null} sx={{ position: 'static' }} />
      </Grid>
      <Grid item xs={12} md={7} lg={6}>
        <Typography variant='h5'>Mobile Preview</Typography>
        <EventCard {...formValues} cb={() => null} sx={{ position: 'static' }} />
      </Grid>
      <Grid item xs={12}>
        <Button variant='contained' onClick={() => updateValues('')}>
          Refresh Previews
        </Button>
      </Grid>
    </FormContainer>
  )
}

export default EventTicketForm
