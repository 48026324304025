import { styled } from '@mui/material/styles'

export const UploadContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'white',
  border: '2px dotted lightgray',
  // Get these working with theme spacing
  margin: '25px 0 15px',
  padding: '35px 20px',

  // '&:focus-within': {
  //   button: {
  //     color: 'Red',
  //     backgroundColor: 'red'
  //   }
  // }
}))

export const FormField = styled('input')(({ theme }) => ({
  // fontSize: '18px',
  display: 'block',
  position: 'absolute',
  width: '100%',
  border: 'none',
  textTransform: 'none',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  opacity: '0',
  // not sure if this works
  '&:focus': {
    outline: 'none'
  }
}))

export const InputLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  // fontSize: '13px',
  color: 'black',
  left: '0',
  top: '-21px'
}))

export const DragDropText = styled('p')(({ theme }) => ({
  fontWeight: 'bold',
  letterSpacing: '2.2px',
  marginTop: '0',
  textAlign: 'center'
}))

export const UploadFileBtn = styled('button')(({ theme }) => ({
  boxSizing: 'border-box',
  appearance: 'none',
  backgroundColor: 'transparent',
  // Come back to this
  border: '2px solid #3498db',
  cursor: 'pointer',
  fontSize: '1rem',
  lineHeight: '1',
  // This too
  padding: '1.1em 2.8em',
  paddingRight: '0',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: '700',
  borderRadius: '6px',
  // Here too
  color: '#3498db',
  display: 'flex',
  position: 'relative',
  overflow: 'hidden',
  zIndex: '1',
  transition: 'color 250ms ease-in-out',
  // fontFamily: 'Open Sans, sans-serif'
  width: '45%',
  '&:after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    top: '0',
    left: '50%',
    width: '0',
    height: '100%',
    transform: 'translateX(-50%)',
    // Here
    background: '#3498db',
    zIndex: '-1',
    transition: 'width 250ms ease-in-out'
  },
  i: {
    fontSize: '22px',
    marginRight: '5px',
    borderRight: '2px solid',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  '@media only screen and (max-width: 500px)': {
    width: '70%'
  },

  '@media only screen and (max-width: 350px)': {
    width: '100%'
  },

  '&:hover': {
    color: '#fff',
    outline: '0',
    background: 'transparent',

    '&:after': {
      width: '110%'
    }
  },

  '&:focus': {
    outline: '0',
    background: 'transparent'
  },

  '&:disabled': {
    opacity: '0.4',
    filter: 'grayscale(100%)',
    pointerEvents: 'none'
  }
}))

export const FilePreviewContainer = styled('article')(({ theme }) => ({
  marginBottom: '35px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  span: {
    fontSize: '14px'
  }
}))

export const PreviewList = styled('section')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: '10px',
  width: '90%',

  '@media only screen and (max-width: 400px)': {
    flexDirection: 'column'
  }
}))

export const FileMetaData = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  position: 'absolute',
  // padding: '10px',
  height: 'inherit',
  width: 'inherit',
  borderRadius: '6px',
  color: 'transparent',
  fontWeight: 'bold',
  top: '0',

  span: {
    margin: '0.5em'
  },

  aside: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'space-between'
  },

  button: {
    color: 'transparent'
  },

  '&:hover': {
    display: 'flex',
    color: 'white',
    backgroundColor: 'rgba(5, 5, 5, 0.55)',

    button: {
      color: 'white'
    }
  }
}))

export const RemoveFileIcon = styled('i')(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.3)'
  }
}))

export const PreviewContainer = styled('section')(({ theme }) => ({
  padding: '0.25rem',
  width: '20%',
  height: '120px',
  borderRadius: '6px',
  boxSizing: 'border-box',
  position: 'relative',
  marginBottom: '1em',

  '@media only screen and (max-width: 750px)': {
    width: '25%'
  },

  '@media only screen and (max-width: 500px)': {
    width: '50%'
  },

  '@media only screen and (max-width: 400px)': {
    width: '100%',
    padding: '0 0 0.4em'
  }
}))

export const ImagePreview = styled('img')(({ theme }) => ({
  borderRadius: '6px',
  width: '100%',
  height: '100%'
}))
