import * as React from 'react'

import { Autocomplete, Chip, TextField } from '@mui/material'
import { MultipleValuesProps } from 'Types'

const MulipleValues: React.FC<MultipleValuesProps> = ({
  options,
  label,
  placeholder,
  onChange
}) => {
  return (
    <Autocomplete
      multiple
      id='tags-filled'
      options={options.map(option => option.value)}
      defaultValue={[options[1].value]}
      freeSolo
      onChange={(e, v) => onChange && onChange(v)}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip variant='outlined' label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          // variant="filled"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

export default MulipleValues
