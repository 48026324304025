import * as React from 'react'
import { Grid } from '@mui/material'
import { Add, Delete, DragHandle } from '@mui/icons-material'
import { useFieldArray } from 'react-hook-form'
import dayjs from 'dayjs'

import { DatePicker, TextField, TimePicker } from 'Components/Form'
import IconButton from 'Components/IconButton'
import { DraggableChild, EventFormProps } from 'Types'
import styles from './styles'
import { useSortable } from '@dnd-kit/sortable'
import FormDnD from './FormDND'
import FormContainer from './FormContainer'

const TimeLoc: React.FC<DraggableChild> = ({ i, id, index, control, itemRemove: timeRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id
  })

  return (
    <Grid
      item
      container
      spacing={2}
      sx={{ ...{ marginTop: '0.25em' }, ...styles.container(transform, transition, isDragging) }}
      ref={setNodeRef}
    >
      <DatePicker
        control={control}
        fieldName={`sections.${index}.time.dates.${i}.date`}
        label={'Event Date'}
        disableSpacer
        gridProps={{ xs: 12, md: 3 }}
        sx={styles.item}
      />
      <TimePicker
        control={control}
        fieldName={`sections.${index}.time.dates.${i}.startTime`}
        label={'Event Start Time'}
        disableSpacer
        gridProps={{ xs: 12, md: 3 }}
        sx={styles.item}
      />
      <TimePicker
        control={control}
        fieldName={`sections.${index}.time.dates.${i}.endTime`}
        label={'Event End Time'}
        disableSpacer
        gridProps={{ xs: 12, md: 3 }}
        sx={styles.item}
      />
      <Grid item xs={2}>
        <IconButton tooltip='Delete Time Range' onClick={() => timeRemove(i)}>
          <Delete />
        </IconButton>
        <IconButton tooltip='Move and Drag Time Range' {...attributes} {...listeners}>
          <DragHandle />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const TimeLocForm: React.FC<EventFormProps> = ({ control, index, ...rest }) => {
  const {
    fields,
    append: timeAppend,
    remove: timeRemove,
    move
  } = useFieldArray({ control, name: `sections.${index}.time.dates` })

  return (
    <FormContainer index={index} {...rest}>
      <FormDnD items={fields} move={move}>
        {fields.map(({ id }, i) => (
          <TimeLoc key={id} i={i} id={id} index={index} control={control} itemRemove={timeRemove} />
        ))}
      </FormDnD>
      <Grid item xs={12}>
        <IconButton
          tooltip='Add Time Range'
          onClick={() =>
            timeAppend({
              date: dayjs(),
              startTime: dayjs(),
              endTime: dayjs()
            })
          }
        >
          <Add />
        </IconButton>
      </Grid>
      <TextField
        control={control}
        fieldName={`sections.${index}.location.name`}
        label={'Location Name'}
        disableSpacer
        gridProps={{ xs: 12, md: 12 }}
        sx={styles.item}
      />
      <TextField
        control={control}
        fieldName={`sections.${index}.location.address`}
        label={'Location Address'}
        disableSpacer
        gridProps={{ xs: 12, md: 12 }}
        sx={styles.item}
      />
      <TextField
        control={control}
        fieldName={`sections.${index}.location.extraInfo`}
        label={'Location ExtraInfo'}
        disableSpacer
        gridProps={{ xs: 12, md: 12 }}
        sx={styles.item}
        multiline
      />
    </FormContainer>
  )
}

export default TimeLocForm
