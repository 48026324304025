import * as React from 'react'

import { Link } from 'react-router-dom'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import {
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import {
  ChevronLeft,
  ChevronRight,
  Dashboard,
  Inventory,
  Logout,
  QrCode,
  ListAlt,
  Event,
  Money
} from '@mui/icons-material'

import { useSideBarState, useFirebase } from 'Hooks'

type Props = {}

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

const DrawerContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
  justifyContent: 'space-between'
}))

const styles = {
  link: {
    textDecoration: 'none',
    color: 'black'
  }
}

const SideBar: React.FC<Props> = () => {
  // const [open, setOpen] = React.useState<boolean>(true)
  const { sideBarState, toggleSideBar } = useSideBarState()
  const { signOutUser } = useFirebase()

  const sideBarLinks = [
    {
      label: 'Dashboard',
      icon: () => <Dashboard />,
      link: '/'
    },
    {
      label: 'Products',
      icon: () => <Inventory />,
      link: '/products'
    },
    {
      label: 'QrCode Gen',
      icon: () => <QrCode />,
      link: '/qrcode'
    },
    {
      label: 'Inventory',
      icon: () => <ListAlt />,
      link: '/inventory'
    },
    {
      label: 'Events',
      icon: () => <Event />,
      link: '/events'
    },
    {
      label: 'Cash',
      icon: () => <Money />,
      link: '/cash'
    }
  ]

  const bottomLinks = [{ label: 'SignOut', icon: () => <Logout /> }]

  return (
    <Drawer variant='permanent' open={sideBarState}>
      <DrawerHeader>
        <IconButton onClick={() => toggleSideBar()}>
          {sideBarState ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
      <DrawerContent>
        <List>
          {sideBarLinks.map(item => (
            <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
              <Link to={item.link} style={styles.link}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: sideBarState ? 'initial' : 'center',
                    px: 2.5
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: sideBarState ? 3 : 'auto',
                      justifyContent: 'center'
                    }}
                  >
                    {item.icon()}
                  </ListItemIcon>
                  <ListItemText primary={item.label} sx={{ opacity: sideBarState ? 1 : 0 }} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
        <List>
          {bottomLinks.map(item => (
            <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: sideBarState ? 'initial' : 'center',
                  px: 2.5
                }}
                // Kinda a hack, prob better to make a constructor that passes in the icon and
                // builds this whole Button component
                onClick={signOutUser}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: sideBarState ? 3 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  {item.icon()}
                </ListItemIcon>
                <ListItemText primary={item.label} sx={{ opacity: sideBarState ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DrawerContent>
    </Drawer>
  )
}

export default SideBar
