import { Info } from '@mui/icons-material'
import { Grid, IconButton, Tooltip as MUIToolTip, Modal, Typography, Box } from '@mui/material'
import * as React from 'react'

type Props = {
  text: string
  placement?:
    | 'left'
    | 'top'
    | 'right'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    paddingLeft: '0px !important'
  },
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '50vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '30px',
    textAlign: 'center',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4
  }
}

const ToolTip: React.FC<Props> = ({ text, placement }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Grid item xs={1} sx={styles.container}>
      <MUIToolTip
        title={<Typography variant='h6'>{text}</Typography>}
        placement={placement || 'right'}
      >
        <IconButton onClick={() => setOpen(true)}>
          <Info color='primary' />
        </IconButton>
      </MUIToolTip>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={styles.modal}>
          <Typography variant='h5'>{text}</Typography>
        </Box>
      </Modal>
    </Grid>
  )
}

export default ToolTip
