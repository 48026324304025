import * as React from 'react'
import { Grid } from '@mui/material'
import { Add, Delete, DragHandle } from '@mui/icons-material'
import { useFieldArray } from 'react-hook-form'
import { useSortable } from '@dnd-kit/sortable'

import { Dropdown, TextField } from 'Components/Form'
import IconButton from 'Components/IconButton'
import { EventFormProps, DraggableChild } from 'Types'
import styles from './styles'
import FormDnD from './FormDND'
import FormContainer from './FormContainer'

const Item: React.FC<DraggableChild> = ({ id, index, i, control, itemRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id
  })

  return (
    <Grid
      container
      item
      xs={12}
      md={6}
      alignContent={'flex-start'}
      columnSpacing={1}
      rowSpacing={2}
      ref={setNodeRef}
      sx={styles.container(transform, transition, isDragging)}
    >
      <Dropdown
        control={control}
        fieldName={`sections.${index}.items.${i}.type`}
        label={'Item Type'}
        selectItems={[{ value: 'text' }, { value: 'button' }]}
        gridProps={{ xs: 11 }}
        // disableGrid
        disableSpacer
      />
      <Grid item xs={1}>
        <IconButton
          tooltip='Move and Drag Item'
          {...attributes}
          {...listeners}
          sx={{ cursor: 'move' }}
        >
          <DragHandle />
        </IconButton>
        <IconButton tooltip='Delete Item' onClick={() => itemRemove(i)}>
          <Delete />
        </IconButton>
      </Grid>
      <TextField
        control={control}
        fieldName={`sections.${index}.items.${i}.text`}
        label={'Text'}
        // disableGrid
        gridProps={{ xs: 12 }}
        disableSpacer
        multiline
        sx={styles.item}
      />
      <TextField
        control={control}
        fieldName={`sections.${index}.items.${i}.url`}
        label={'URL'}
        // disableGrid
        gridProps={{ xs: 12 }}
        disableSpacer
        sx={styles.item}
      />
    </Grid>
  )
}

const ItemsForm: React.FC<EventFormProps> = ({ control, index, ...rest }) => {
  const {
    fields,
    append: itemAppend,
    remove: itemRemove,
    move
  } = useFieldArray({ control, name: `sections.${index}.items` })

  const tooltip =
    'This form is meant to add in additional normal text or links, Choose what size you want it to be, then if you want it to show up as a clickable square button select button in item type, otherwise if you want a clickable link leave it as text, this will only turn into a button if you enter in a url '

  return (
    <FormContainer index={index} toolTip={tooltip} {...rest}>
      <Dropdown
        control={control}
        fieldName={`sections.${index}.variant`}
        label={'Text Size'}
        selectItems={[
          { value: 'h1' },
          { value: 'h2' },
          { value: 'h3' },
          { value: 'h4' },
          { value: 'h5' }
        ]}
        gridProps={{ xs: 3 }}
        sx={styles.item}
      />
      <FormDnD items={fields} move={move}>
        {fields.map(({ id }, i) => (
          <Item key={id} id={id} i={i} index={index} control={control} itemRemove={itemRemove} />
        ))}
      </FormDnD>
      <Grid item xs={1}>
        <IconButton
          tooltip='Add Item'
          onClick={() => itemAppend({ type: 'text', text: '', url: '' })}
        >
          <Add />
        </IconButton>
      </Grid>
    </FormContainer>
  )
}

export default ItemsForm
