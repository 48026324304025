import * as React from 'react'
import { Grid } from '@mui/material'

import { BasicUpload, Dropdown, TextField } from 'Components/Form'
import { EventFormProps } from 'Types'
import styles from './styles'
import FormContainer from './FormContainer'

const ZoomImageForm: React.FC<EventFormProps> = ({ control, index, ...rest }) => {
  const tooltip =
    'These Images Zoom if clicked on, easier to fit them nicely on the page if you specify the orientation of the image '

  return (
    <FormContainer index={index} toolTip={tooltip} {...rest}>
      <Grid item xs={12} md={6}>
        <BasicUpload
          control={control}
          fieldName={`sections.${index}.image`}
          multiple={false}
          label={''}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Dropdown
          control={control}
          fieldName={`sections.${index}.orientation`}
          label={'Orientation of Image'}
          selectItems={[{ value: 'horizontal' }, { value: 'vertical' }]}
          disableGrid
          sx={{ marginBottom: '1em' }}
        />
        <TextField
          control={control}
          fieldName={`sections.${index}.alt`}
          label={'Image Alt Description'}
          disableGrid
          sx={styles.item}
        />
      </Grid>
    </FormContainer>
  )
}

export default ZoomImageForm
