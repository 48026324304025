import * as React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Dropdown } from 'Components/Form'
import { useAxios } from 'Hooks'
import { SelectItems } from 'Types'

type RSVPForm = {
  eventName: string
}
const defaultValues: RSVPForm = {
  eventName: ''
}

type NumOfUsers = {
  totalUsers: number
  emailUsers: number
}

const RsvpCounter: React.FC = () => {
  const [dropDown, setDropDown] = React.useState<SelectItems[]>()
  const [numberRsvp, setNumberRsvp] = React.useState<NumOfUsers>()
  const { control, getValues } = useForm({ defaultValues })
  const { get } = useAxios()

  React.useEffect(() => {
    if (!dropDown) {
      get<{ urlName: string }[]>('events/names').then(({ data }) => {
        console.log({ data })
        setDropDown(data.map(item => ({ value: item.urlName })))
      })
    }
  }, [dropDown, get])

  const onSubmit = async () => {
    const values = getValues()
    const { data } = await get<NumOfUsers>(`admin/events/rsvp/${values.eventName}`)
    setNumberRsvp(data)
  }

  return (
    <Grid container item xs={12} alignItems={'center'} justifyContent={'flex-start'}>
      {dropDown && (
        <>
          <Dropdown
            control={control}
            fieldName={'eventName'}
            label={'Choose Event'}
            selectItems={dropDown}
            defaultValue={dropDown[0]}
            disableSpacer
            sx={{ width: '100%' }}
            gridProps={{ xs: 4 }}
          />
          <Grid item xs={2}>
            <Button variant='contained' type='submit' onClick={onSubmit}>
              Submit
            </Button>
          </Grid>
          {numberRsvp ? (
            <Grid item xs={7}>
              <Typography variant='h5' textAlign={'left'}>
                Total Number of users Rsvp'd are: {numberRsvp.totalUsers}
              </Typography>
              <Typography variant='h5' textAlign={'left'}>
                Number of users Rsvp'd with email are: {numberRsvp.emailUsers}
              </Typography>
            </Grid>
          ) : null}
        </>
      )}
    </Grid>
  )
}

export default RsvpCounter
