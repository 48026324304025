import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

import { ConfirmDialogProps } from 'Types'

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  setOpen,
  loading,
  title,
  description,
  confirmButtonText,
  onConfirm,
  confirmColour
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color={confirmColour === 'primary' || confirmColour === 'secondary' ? 'error' : 'primary'}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color={confirmColour || 'error'}
          disabled={loading}
          onClick={onConfirm}
          autoFocus
        >
          {confirmButtonText || 'Permanently Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
