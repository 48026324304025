import * as React from 'react'
import { Grid } from '@mui/material'
import { Add, Delete, DragHandle } from '@mui/icons-material'
import { UseFieldArrayInsert, useFieldArray } from 'react-hook-form'
import { useSortable } from '@dnd-kit/sortable'
import { Dayjs } from 'dayjs'

import { BasicUpload, FreeSolo, TextField } from 'Components/Form'
import { EventFormProps, Vendor, DraggableChild, BackendEvent } from 'Types'
import styles from './styles'
import FormDnD from './FormDND'
import FormContainer from './FormContainer'
import IconButton from 'Components/IconButton'

type VendorProps = {
  vendors: Vendor[]
} & EventFormProps

type VendorItemProps = {
  vendors: Vendor[]
  update: any
  insert: UseFieldArrayInsert<BackendEvent<Vendor, Dayjs>, `sections.${number}.vendors`>
} & DraggableChild

const VendorItem: React.FC<VendorItemProps> = ({
  control,
  i,
  index,
  id,
  itemRemove: vendorRemove,
  insert,
  vendors,
  update
}) => {
  const getVendor = async (vendorHandle: string) => {
    let vendor: Vendor | undefined
    vendors.forEach(v => {
      if (v.handle === vendorHandle) vendor = v
    })

    if (!vendor) return vendor
    // if (typeof vendor.image === 'string') vendor.image = await getImage(vendor.image)
    return vendor
  }

  const vendorNames = vendors.map(vendor => ({ value: vendor.handle }))

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id
  })

  return (
    <Grid
      container
      spacing={2}
      ref={setNodeRef}
      sx={{ ...{ marginTop: '0.25em' }, ...styles.container(transform, transition, isDragging) }}
    >
      <Grid item sx={styles.item} xs={12} md={6}>
        <BasicUpload
          control={control}
          fieldName={`sections.${index}.vendors.${i}.image`}
          multiple={false}
          label={'Vendor'}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton tooltip='Delete Vendor' onClick={() => vendorRemove(i)}>
            <Delete />
          </IconButton>
          <IconButton
            tooltip='Move and Drag Vendor'
            {...listeners}
            {...attributes}
            sx={{ cursor: 'move' }}
          >
            <DragHandle />
          </IconButton>
        </div>
        <FreeSolo
          options={vendorNames ? vendorNames : []}
          control={control}
          fieldName={`sections.${index}.vendors.${i}.handle`}
          label={'Find or Create Vendor'}
          disableGrid
          setValue='value'
          sx={styles.item}
          onChange={async ({ value }) => {
            if (value) {
              const vendor = await getVendor(value)
              if (vendor) update(i, vendor)
            }
          }}
        />

        <TextField
          control={control}
          fieldName={`sections.${index}.vendors.${i}.url`}
          label={'Vendor Handle URL'}
          sx={styles.item}
          // gridProps={{ xs: 6, height: 'min-content' }}
          disableGrid
          disableSpacer
        />

        <TextField
          control={control}
          fieldName={`sections.${index}.vendors.${i}.alt`}
          label={'Vendor Handle Alt description'}
          sx={styles.item}
          // gridProps={{ xs: 6 }}
          disableGrid
          disableSpacer
        />
        <IconButton
          tooltip='Add New Vendor here'
          onClick={() => insert(++i, { image: '', handle: '', url: '', alt: '' })}
        >
          <Add />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const VendorForm: React.FC<VendorProps> = ({ vendors, control, index, ...rest }) => {
  const {
    fields,
    insert,
    remove: vendorRemove,
    update,
    move
  } = useFieldArray({ control, name: `sections.${index}.vendors` })
  
  const tooltip = `There is multiple ways to use this vendor form, First easiest way is if the vendor has been used in a previous event, in that case then just type in the Find or Create Vendor field and select the one you want.
                  If you're creating a new vendor then type the vendor name into the field and click the Add button that pops up with the name, this name is what shows up on the page so best to prefix it with an @, the image will be auto displayed as a circle of the image on the event page.
                  If you need to update a vendors details, then follow the first steps to populate the vendors details, then change what you need and it will get updated when you save.`

  return (
    <FormContainer index={index} toolTip={tooltip} {...rest}>
      <FormDnD items={fields} move={move}>
        {fields.map(({ id }, i) => {
          return (
            <VendorItem
              key={id}
              control={control}
              i={i}
              id={id}
              index={index}
              itemRemove={vendorRemove}
              vendors={vendors}
              update={update}
              insert={insert}
            />
          )
        })}
      </FormDnD>
    </FormContainer>
  )
}

export default VendorForm
