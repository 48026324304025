import * as React from 'react'
import { Snackbar } from '@mui/material'

import Alert from 'Components/Alert'
import { useAlertState } from 'Hooks/useGlobalState'

type Props = {}

const SnackAlert: React.FC<Props> = () => {
  const { alertState, clearAlert } = useAlertState()

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    clearAlert()
  }

  return (
    <Snackbar open={alertState.open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={alertState.status.severity} sx={{ width: '100%' }}>
        {alertState.status.msg}
      </Alert>
    </Snackbar>
  )
}

export default SnackAlert
