import * as React from 'react'

import { Controller } from 'react-hook-form'
import { TextField as MUITextField } from '@mui/material'

import gridWrapper from 'Utils/GridWrapper'
import { FormTextFieldProps } from 'Types'

const FormTextField: React.FC<FormTextFieldProps> = ({
  fieldName,
  label,
  control,
  controllerProps,
  onChangeFilter,
  ...textFieldProps
}) => {
  return (
    <Controller
      name={fieldName}
      {...controllerProps}
      render={({ field: { onChange, ...field }, formState: { errors } }) => {
        const fieldError = errors[fieldName]

        return (
          <>
            <MUITextField
              label={label}
              variant='outlined'
              error={fieldError ? true : false}
              onChange={event => {
                if (onChangeFilter) onChange(onChangeFilter(event.target.value))
                else onChange(event)
              }}
              {...textFieldProps}
              {...field}
            />
            {fieldError && <p>{fieldError.message}</p>}
          </>
        )
      }}
      control={control}
    />
  )
}

export const TextField = ({
  disableGrid,
  disableSpacer,
  gridProps,
  spacerProps,
  ...props
}: FormTextFieldProps) => {
  return gridWrapper<FormTextFieldProps>({
    disableGrid,
    disableSpacer,
    Component: FormTextField,
    props,
    gridProps,
    spacerProps,
    key: props.fieldName
  })
}

export default TextField
