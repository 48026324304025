import * as React from 'react'
import { Controller } from 'react-hook-form'
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker'
import { TextField } from '@mui/material'
import dayjs from 'dayjs'

import gridWrapper from 'Utils/GridWrapper'
import { FormDateProps } from 'Types'

const FormDatePicker: React.FC<FormDateProps> = ({
  fieldName,
  label,
  control,
  controllerProps,
  textFieldProps,
  ...dateFieldProps
}) => {
  return (
    <Controller
      name={fieldName}
      {...controllerProps}
      render={({ field: { onChange, ref, value } }) => {
        return (
          <MUIDatePicker
            {...dateFieldProps}
            slots={{ textField: props => <TextField {...props} /> }}
            label={label}
            onChange={onChange}
            inputRef={ref}
            value={dayjs(value)}
          />
        )
      }}
      control={control}
    />
  )
}

export const DatePicker = ({
  disableGrid,
  disableSpacer,
  gridProps,
  spacerProps,
  ...props
}: FormDateProps) => {
  return gridWrapper<FormDateProps>({
    disableGrid,
    disableSpacer,
    Component: FormDatePicker,
    props,
    gridProps,
    spacerProps,
    key: props.fieldName
  })
}

export default DatePicker
