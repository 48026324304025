import * as React from 'react'

import { Grid, Typography, Skeleton } from '@mui/material'
import { Link } from 'react-router-dom'

import LazyLoadImg from 'Components/LazyLoadImg'
import { useProductState } from 'Hooks'
import { Product } from 'Types'

const styles = {
  grid: {
    // height: '15em',
    '&:hover, &:focus': {
      borderRadius: '9px',
      boxShadow: '0 2px 18px rgb(34 34 34 / 20%)'
    }
  },
  image: {
    height: '70%',
    width: '100%'
  },
  spacer: {
    marginTop: '0.5em'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  }
}

type Props = {
  prod: Product
}

const ProductCard: React.FC<Props> = ({ prod }) => {
  // const { currency } = useCurrencyState()
  const { addImagesToProd } = useProductState()

  return (
    <Grid item xs={3} sx={styles.grid}>
      <Link to={`/products/${prod.productId}`} style={styles.link}>
        <LazyLoadImg
          imageUrl={`https://jkcharmsprodimages.s3.us-west-2.amazonaws.com/${prod.productId}/mainImage/${prod.productId}-0`}
          setImageToState={(images: Blob[]) => addImagesToProd(images, prod.productId)}
          image={prod.images[0]}
        />
        {prod.name ? (
          <Typography variant='h6'>{prod.name}</Typography>
        ) : (
          <Skeleton variant='rectangular' sx={styles.spacer} />
        )}
        {prod.price.base ? (
          <Typography variant='body1'>{prod.price.base}</Typography>
        ) : (
          <Skeleton variant='rectangular' sx={styles.spacer} />
        )}
      </Link>
    </Grid>
  )
}

export default ProductCard
