import * as React from 'react'
import { Grid, Theme, Typography } from '@mui/material'

import { EventTimeLocation } from 'Types'
import { Dayjs } from 'dayjs'

const styles = {
  locTimeContainer: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  loc: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.5em',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }),
  time: (theme: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 2,
    paddingBottom: 2,
    [theme.breakpoints.down('lg')]: {
      borderBottom: '2px solid black'
    },
    [theme.breakpoints.up('lg')]: {
      borderRight: '2px solid black'
    }
  }),
  textItem: (theme: Theme) => ({
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5em'
    }
  })
}

const TimeLocation: React.FC<EventTimeLocation<Dayjs>> = ({ time, location }) => {
  return (
    <Grid item xs={11} md={8} sx={styles.locTimeContainer}>
      <Typography variant='h4'>Time and Location</Typography>
      <Grid container>
        <Grid item xs={12} md={12} lg={6} sx={styles.time}>
          {time.dates.map(({ date, endTime, startTime }) => (
            <div key={date.toString()}>
              <Typography sx={styles.textItem} variant='h5'>
                {date.format('ddd MMM DD YYYY')}
              </Typography>
              <Typography sx={styles.textItem} variant='h5'>
                {startTime.format('hh:mm a')} - {endTime.format('hh:mm a')}
              </Typography>
            </div>
          ))}
          {time.extraInfo && (
            <Typography sx={styles.textItem} variant='h5'>
              {time.extraInfo}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={6} sx={styles.loc}>
          <Typography sx={styles.textItem} variant='h5'>
            {location.name}
          </Typography>
          <Typography sx={styles.textItem} style={{ paddingBottom: '1em' }} variant='h5'>
            {location.address}
          </Typography>
          {location.extraInfo && (
            <Typography sx={styles.textItem} variant='h5'>
              {location.extraInfo}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TimeLocation
