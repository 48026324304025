import * as React from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Button, Grid } from '@mui/material'

import { useAxios, useProductState } from 'Hooks'
import { FreeSolo, MultipleValues } from 'Components/Form'
import { Upload, TextField } from 'Components/Form'
import { NewProductFields, Product } from 'Types'
import { useNavigate } from 'react-router-dom'

type Props = {}

const styles = {
  container: {
    marginTop: '2em',
    justifyContent: 'center'
  },
  item: {
    width: '100%'
  }
}

const NewProduct: React.FC<Props> = () => {
  const { post, put } = useAxios()
  const { addProd } = useProductState()
  const navigate = useNavigate()
  const defaultValues: NewProductFields = {
    imageFiles: [],
    name: '',
    description: '',
    category: { value: '' },
    price: 0,
    quantity: 0,
    tags: []
  }
  const { handleSubmit, control } = useForm<NewProductFields>({ defaultValues })

  const onSubmit: SubmitHandler<NewProductFields> = async data => {
    try {
      console.log({ data })
      const { imageFiles, ...rest } = data
      const product = {
        ...rest,
        category: rest.category.value,
        price: { base: rest.price }
      }

      console.log({ product })
      const res: any = await post('admin/products', { product, numOfImages: imageFiles.length })
      console.log({ res })

      const {
        data: { newProduct, signedUrlArray }
      } = res
      if (!signedUrlArray || !Array.isArray(signedUrlArray))
        throw new Error('Issue with uploading images!')

      await Promise.all(
        signedUrlArray.map((url: string, i) =>
          put(url, imageFiles[i], { headers: { 'Content-Type': imageFiles[i].type } })
        )
      )

      const createdProduct: Product = {
        ...rest,
        category: rest.category.value,
        price: { base: rest.price },
        images: imageFiles,
        productId: newProduct.productId
      }

      addProd(createdProduct)
      navigate('/products')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} sx={styles.container}>
        <Grid item sx={styles.item}>
          <Upload
            control={control}
            fieldName={'imageFiles'}
            label={'Upload your Product Photos here!'}
            multiple
            maxFileSize={10000000}
          />
        </Grid>
        <TextField control={control} fieldName={'name'} label={'Product Name'} />
        <TextField control={control} fieldName={'description'} label={'Product Description'} />
        {/* <TextField control={control} fieldName={'category'} label={'Product Category'} /> */}
        <FreeSolo
          control={control}
          fieldName={'category'}
          label={'Product Category'}
          placeholder={'Type to add new Category'}
          options={[{ value: 'testing' }, { value: 'Anotha one' }, { value: 'show me da money' }]}
        />
        <TextField control={control} fieldName={'price'} label={'Product Price'} />
        <TextField control={control} fieldName={'quantity'} label={'Product Quantity'} />
        <MultipleValues
          control={control}
          fieldName={'tags'}
          label={'Product Tags'}
          options={[{ value: 'testing' }, { value: 'Anotha one' }, { value: 'show me da money' }]}
        />
        {/* <TextField control={control} fieldName={'tags'} label={'Product Tags'} /> */}
        <Grid item>
          <Button variant='contained' type='submit'>
            Submit Form
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default NewProduct
