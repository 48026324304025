import * as React from 'react'
import { Box, CardMedia, styled, Typography, Button } from '@mui/material'

import barcode from 'Assets/barcode.webp'
import VerticalTicketRip from './TicketRip'
import {
  createVerticalRipStyles,
  EventTicketCard,
  EventTicketLeft,
  EventTicketLeftContent,
  EventTicketRight,
  styles
} from './styles'
import { EventTicket as EventTicketProps } from 'Types'

export const EventTicket: React.FC<EventTicketProps> = ({ bg, cb, leftContent, rightContent, sx }) => {
  const verticalRipStyles = createVerticalRipStyles({ bg })

  return (
    <EventTicketCard elevation={0} sx={sx} onClick={() => cb()}>
      <EventTicketLeft className={'moveLeft'} bg={bg}>
        <EventTicketLeftContent>
          <Typography variant='h5' sx={styles.label}>
            {leftContent.header}
          </Typography>
          <Typography variant='h6' sx={styles.label}>
            {leftContent.sub}
          </Typography>
          {leftContent.barcode ? <CardMedia sx={styles.media} image={barcode} /> : undefined}
        </EventTicketLeftContent>
      </EventTicketLeft>
      <VerticalTicketRip classes={verticalRipStyles} />
      <EventTicketRight className={'moveRight'} bg={bg}>
        <Typography variant='h5' sx={styles.header}>
          {rightContent.firstHeader}
        </Typography>
        <Typography variant='h5' sx={styles.header}>
          {rightContent.secondHeader}
        </Typography>
        <Typography variant='body1' sx={styles.label}>
          {rightContent.firstSub}
        </Typography>
        <Typography variant='body1' sx={styles.label}>
          {rightContent.secondSub}
        </Typography>
      </EventTicketRight>
    </EventTicketCard>
  )
}

const EventStickyCard = styled(Box, { shouldForwardProp: prop => prop !== 'bg' })<{
  bg: string
}>(({ theme, bg }) => ({
  height: '15vh',
  width: '100%',
  backgroundColor: 'white',
  position: 'sticky',
  zIndex: 100,
  bottom: 0,
  background: `url(${bg})`,
  backgroundSize: 'cover'
}))

export const EventCard: React.FC<EventTicketProps> = ({ bg, cb, rightContent }) => {
  return (
    <EventStickyCard bg={bg}>
      <Typography variant='h6'>{rightContent.firstSub}</Typography>
      <Typography variant='h6'>{rightContent.secondSub}</Typography>
      <Button variant='contained' color='primary' onClick={() => cb()}>
        {rightContent.secondHeader}
      </Button>
    </EventStickyCard>
  )
}

const EventRSVP: React.FC<EventTicketProps> = props => {
  const width = window.innerWidth

  return width > 768 ? <EventTicket {...props} /> : <EventCard {...props} />
}

export default EventRSVP
