import * as React from 'react'

import {
  Grid,
  Typography,
  Skeleton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'
import { Link } from 'react-router-dom'

import { useAxios, useAlertState } from 'Hooks'
import LazyLoadImg from 'Components/LazyLoadImg'
import SnackAlert from 'Components/SnackAlert'
import BasicDropdown from 'Components/BasicDropdown'
import { BasicEvent } from 'Types'
import ToolTip from 'Components/Tooltip'

const styles = {
  grid: {
    '&:hover, &:focus': {
      borderRadius: '9px',
      boxShadow: '0 2px 18px rgb(34 34 34 / 20%)'
    }
  },
  image: {
    height: '70%',
    width: '100%'
  },
  spacer: {
    marginTop: '0.5em'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  }
}

type Props = {
  event: BasicEvent
  updateEvents: React.Dispatch<React.SetStateAction<boolean>>
}

const EventCard: React.FC<Props> = ({ event, updateEvents }) => {
  const { setAlert } = useAlertState()
  const { remove, patch } = useAxios()
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [activeState, setActiveState] = React.useState('inactive')

  const deleteEvent = async () => {
    try {
      setLoading(true)
      await remove(`admin/events/${event.urlName}`)
      setAlert({ msg: `Permanently deleted event: ${event.urlName}!`, severity: 'success' })
      setLoading(false)
      setOpen(false)
      updateEvents(true)
    } catch (e) {
      setAlert({
        msg: `There was an issue deleting event: ${event.urlName}`,
        severity: 'error'
      })
      setLoading(false)
    }
  }

  const updateEvent = async () => {
    try {
      setLoading(true)
      await patch('admin/events', { event: { ...event, status: activeState } })
      setAlert({ msg: `${event.urlName} has been updated!`, severity: 'success' })
      setLoading(false)
      updateEvents(true)
    } catch (e) {
      console.log({ e })
      setAlert({ msg: `There was an issue updating event: ${event.urlName}.`, severity: 'error' })
      setLoading(false)
    }
  }

  const tooltip = `This satus affects how the event is shown to users, if its inactive then users dont see it at all, if its active then it can be seen wether in upcoming or past events,
   if its featured then it will be shown in the carousel on the front page and event page`

  return (
    <Grid item container xs={12} sm={6} md={3} rowSpacing={1} sx={styles.grid}>
      <Grid item xs={12}>
        <LazyLoadImg imageUrl={event.eventData.thumbnail} style={{ borderRadius: '1.5em' }} />
        {event.urlName ? (
          <Typography variant='h6'>{event.urlName}</Typography>
        ) : (
          <Skeleton variant='rectangular' sx={styles.spacer} />
        )}
      </Grid>
      <Grid item xs={12} justifyContent='space-between' display='flex'>
        <Button variant='contained'>
          <Link to={`/events/${event.urlName}`} style={styles.link}>
            Preview
          </Link>
        </Button>
        <Button variant='contained'>
          <Link to={`/events/edit/${event.urlName}`} style={styles.link}>
            Edit
          </Link>
        </Button>
        <Button variant='contained' onClick={() => setOpen(true)}>
          Delete
        </Button>
      </Grid>

      <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center'>
        <Grid item>
          <Typography variant='h6'> Set Events status </Typography>
        </Grid>
        <ToolTip text={tooltip} />
      </Grid>

      <Grid item xs={12}>
        <BasicDropdown
          label='Status'
          selectItems={[{ value: 'active' }, { value: 'featured' }, { value: 'inactive' }]}
          setState={setActiveState}
          defaultValue={event.status}
        />
        <Button variant='contained' onClick={updateEvent}>
          Save Status
        </Button>
      </Grid>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {`Are you sure you want to permanently Delete event ${event.urlName}?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {
              'This will permanently delete all the data associated with this event, including details and images uploaded, will not remove any vendors added for this event however.'
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant='contained'
            color='error'
            disabled={loading}
            onClick={deleteEvent}
            autoFocus
          >
            Permanently Delete
          </Button>
        </DialogActions>
      </Dialog>
      <SnackAlert />
    </Grid>
  )
}

export default EventCard
