import * as React from 'react'
import {
  Typography,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Theme,
  Box,
  styled
} from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

import { useSideBarState } from 'Hooks'

const styles = {
  root: {
    flexGrow: 1
  },
  menuButton: (theme: Theme) => ({
    marginRight: theme.spacing(2)
  }),
  title: (theme: Theme) => ({
    flexGrow: 1,
    color: '#d1f9d1',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8em'
    }
  }),
  link: {
    textDecoration: 'none',
    color: '#d1f9d1'
  }
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

// Used to be 240 but then added in a grid template on the left where sidebar is, 150 fit best now
const drawerWidth = 150

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth - 16}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

type Props = {}

const NavBar: React.FC<Props> = () => {
  const { sideBarState } = useSideBarState()
  const { pathname } = useLocation()

  const getPathName = () => {
    const pathName = pathname.split('/')[1]
    if (pathName === 'login') return 'JK Charms'
    else if (pathName === '') return 'Dashboard'
    else return pathName[0].toUpperCase() + pathName.substring(1)
  }

  return (
    <Box sx={styles.root}>
      <AppBar position='static' open={sideBarState}>
        <Toolbar>
          {/* <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={toggleSideBar}
            edge='start'
            sx={{
              marginRight: 5,
              ...(sideBarState && { display: 'none' })
            }}
          >
            <Menu />
          </IconButton> */}
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"> */}
          {/* <MenuIcon /> */}
          {/* </IconButton> */}
          <Typography variant='h2' sx={styles.title}>
            <Link to={`/`} style={styles.link}>
              {getPathName()}
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      {/* <SignInUpModal open={open} setClose={() => setOpen(false)} /> */}
    </Box>
  )
}

export default NavBar
