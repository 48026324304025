import { Grid } from '@mui/material'
import SignIn from 'Components/SignIn'
import * as React from 'react'

type Props = {}

const styles = {
  container: {
    marginTop: '2em',
    justifyContent: 'center'
  }
}

const Login: React.FC<Props> = () => {
  return (
    <Grid container spacing={2} sx={styles.container}>
      <SignIn />
    </Grid>
  )
}

export default Login
