import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

import { OptionType, FreeSoloProps } from 'Types'

const filter = createFilterOptions<OptionType>()

const FreeSolo: React.FC<FreeSoloProps> = ({
  options,
  onChange,
  label,
  placeholder,
  defaultValue,
  sx
}) => {
  const [value, setValue] = React.useState<OptionType | null>(defaultValue || null)

  // React.useEffect(() => {
  //   if (value && onChange) onChange(value)
  // }, [value, onChange])

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          const option = { value: newValue }
          setValue(option)
          if (onChange) onChange(option)
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          const option = { value: newValue.inputValue }
          setValue(option)
          if (onChange) onChange(option)
        } else {
          setValue(newValue)
          if (onChange && newValue) onChange(newValue)
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        const { inputValue } = params
        // Suggest the creation of a new value
        const isExisting = options.some(option => inputValue === option.value)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            value: `Add "${inputValue}"`
          })
        }

        return filtered
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id='free-solo-autocomplete'
      options={options}
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue
        }
        // Regular option
        return option.value
      }}
      renderOption={(props, option) => <li {...props}>{option.name || option.value}</li>}
      sx={sx}
      freeSolo
      renderInput={params => <TextField {...params} label={label} placeholder={placeholder} />}
    />
  )
}

export default FreeSolo
