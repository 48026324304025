import * as React from 'react'

import { IconButton as Iconbutton, Tooltip, IconButtonProps, TooltipProps } from '@mui/material'

type Props = {
  tooltip: string
  tooltipProps?: Omit<TooltipProps, 'title'>
} & IconButtonProps

const IconButton: React.FC<Props> = ({ tooltip, tooltipProps, children, ...iconButton }) => {
  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      <Iconbutton {...iconButton}>{children}</Iconbutton>
    </Tooltip>
  )
}

export default IconButton
