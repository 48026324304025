import * as React from 'react'
import { Grid } from '@mui/material'

import Header from './Header'
import { EventFormProps } from 'Types'
import { BasicUpload, ColorPicker } from 'Components/Form'

const BackgroundForm: React.FC<Omit<EventFormProps, "type" | "activeId">> = ({ control, index, ...rest }) => {
  const tooltip = 'There is a couple of ways to show a background on the page, you can add an Image and mobile version of the Image to display an image as the background, or you can just select a solid colour, also Thumbnail is required best size is around 2048 x 2048'

  return (
    <>
      <Header
        title={'Background Images/Colour and Thumbnail'}
        index={index}
        enableActions={false}
        toolTip={tooltip}
        {...rest}
      />
      <Grid item xs={12} md={6}>
        <BasicUpload
          control={control}
          fieldName={`eventData.bg.full`}
          multiple={false}
          label={'Background'}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <BasicUpload
          control={control}
          fieldName={`eventData.bg.mobile`}
          multiple={false}
          label={'Moble Background'}
        />
      </Grid>
      <ColorPicker
        control={control}
        fieldName={'eventData.bg.colour'}
        label={'Pick a BG Colour'}
        gridProps={{ xs: 12, md: 6 }}
        disableSpacer
      />
      <Grid item xs={12} md={6}>
        <BasicUpload
          control={control}
          fieldName={`eventData.thumbnail`}
          multiple={false}
          label={'Thumbnail'}
          controllerProps={{
            rules: {
              required: 'Thumbnail is required'
            }
          }}
        />
      </Grid>
    </>
  )
}

export default BackgroundForm
