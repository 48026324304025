import { CSS, Transform } from '@dnd-kit/utilities'
import { Transitions } from '@mui/material'

const styles = {
  container: (
    transform: Transform | null,
    transition: string | undefined,
    isDragging: boolean
  ) => ({
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1
  }),
  item: {
    width: '100%',
    marginBottom: '1em'
  },
  header: {
    width: '100%',
    borderBottom: '1px dashed grey',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    paddingBottom: '1em'
  },
  footer: {
    width: '100%'
  },
  addButton: (clicked: boolean, transitions: Transitions) => ({
    transition: transitions.create(['transform'], {
      duration: transitions.duration.short
    }),
    transform: clicked ? 'rotate(45deg)' : 'rotate(0deg)'
  })
}

export default styles
