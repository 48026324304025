import { atom } from 'recoil'
import { Product, Currencies, Auth, AlertState } from 'Types'
import { CashEvent, VendoringEvent } from 'Types/Products'

export const currency = atom<Currencies>({
  key: 'currency',
  default: 'CAD'
})

export const prodState = atom<Product[]>({
  key: 'prodState',
  default: []
})

export const authState = atom<Auth>({
  key: 'auth',
  // See if If I can read if user is signed in here
  default: { isSignedIn: false }
})

export const isCheckingifSignedIn = atom<boolean>({
  key: 'isChecking',
  default: true
})

export const sideBarState = atom<boolean>({
  key: 'sideBar',
  default: false
})

export const alertState = atom<AlertState>({
  key: 'alert',
  default: {
    open: false,
    status: {
      severity: 'success',
      msg: ''
    }
  }
})

export const eventTicketFormState = atom<boolean>({
  key: 'eventTicket',
  default: false
})

export const cashEventState = atom<CashEvent | undefined>({
  key: 'cashEvent',
  default: undefined
})

export const vendoringEventState = atom<VendoringEvent | undefined>({
  key: 'vendoringEvent',
  default: undefined
})
