import * as React from 'react'

import { Controller } from 'react-hook-form'
import { TextField as MUITextField, IconButton, Popover } from '@mui/material'
import { EmojiEmotions } from '@mui/icons-material'
import EmojiPicker from 'emoji-picker-react'

import gridWrapper from 'Utils/GridWrapper'
import { FormTextFieldProps } from 'Types'

const EmojiFormTextField: React.FC<FormTextFieldProps> = ({
  fieldName,
  label,
  control,
  controllerProps,
  onChangeFilter,
  ...textFieldProps
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Controller
      name={fieldName}
      {...controllerProps}
      render={({ field: { onChange, value, ...field }, formState: { errors } }) => {
        const fieldError = errors[fieldName]

        return (
          <>
            <MUITextField
              label={label}
              value={value}
              variant='outlined'
              error={fieldError ? true : false}
              onChange={event => {
                onChange(event.target.value)
                // if (onChangeFilter) onChange(onChangeFilter(event.target.value))
                // else onChange(event)
              }}
              InputProps={{
                endAdornment: (
                  <IconButton size='small' onClick={handleClick}>
                    <EmojiEmotions />
                  </IconButton>
                )
              }}
              {...textFieldProps}
              {...field}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <EmojiPicker
                onEmojiClick={emoji => {
                  onChange(value + emoji.emoji)
                }}
              />
            </Popover>
            {fieldError && <p>{fieldError.message}</p>}
          </>
        )
      }}
      control={control}
    />
  )
}

export const EmojiTextField = ({
  disableGrid,
  disableSpacer,
  gridProps,
  spacerProps,
  ...props
}: FormTextFieldProps) => {
  return gridWrapper<FormTextFieldProps>({
    disableGrid,
    disableSpacer,
    Component: EmojiFormTextField,
    props,
    gridProps,
    spacerProps,
    key: props.fieldName
  })
}

export default EmojiTextField
