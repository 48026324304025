import { MenuItem, Select } from '@mui/material'
import * as React from 'react'

import { BasicFormProps } from 'Types'

const BasicDropdown: React.FC<BasicFormProps> = ({
  label,
  selectItems,
  setState,
  defaultValue
}) => {
  return (
    <Select
      label={label}
      defaultValue={defaultValue || selectItems[0].value}
      onChange={e => setState(e.target.value as string)}
    >
      {selectItems.map((item, index) => (
        <MenuItem key={`${item.value}-${index}`} value={item.value}>
          {item.name || item.value}
        </MenuItem>
      ))}
    </Select>
  )
}

export default BasicDropdown
