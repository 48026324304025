import * as React from 'react'
import { useInView } from 'react-intersection-observer'
import { Skeleton } from '@mui/material'

import { useAxios } from 'Hooks'

type Props = {
  image?: Blob
  imageUrl?: string
  getImageUrl?: () => string
  setRef?: (node: HTMLImageElement) => void
  setImageToState?: (images: Blob[]) => void
  style?: React.CSSProperties
}

const LazyLoadImg: React.FC<Props> = ({
  image: parentImage,
  imageUrl,
  getImageUrl,
  setRef,
  setImageToState,
  style
}) => {
  const { ref, inView } = useInView({ threshold: 0 })
  const [image, SetImage] = React.useState<string | undefined>(undefined)
  const [cachedImage, setCachedImage] = React.useState<Blob | undefined>(parentImage)
  const { get } = useAxios()

  const getImage = React.useCallback(
    async (imageUrl: string) => {
      const { data } = await get<Blob>(imageUrl, { responseType: 'blob' })
      const blobImageUrl = URL.createObjectURL(data)
      setImageToState && setImageToState([data])
      setCachedImage(data as Blob)
      SetImage(blobImageUrl)
    },
    [get, setImageToState]
  )

  const revokeUrl = React.useCallback(() => {
    if (image) {
      URL.revokeObjectURL(image)
      SetImage(undefined)
    }
  }, [image])

  const setCached = React.useCallback(
    () => cachedImage && SetImage(URL.createObjectURL(cachedImage)),
    [cachedImage, SetImage]
  )

  React.useEffect(() => {
    if (inView && !image) {
      if (cachedImage && !image) setCached()
      else if (imageUrl) getImage(imageUrl)
      else if (getImageUrl) getImage(getImageUrl())
    } else if (!inView && image) revokeUrl()
  }, [inView, cachedImage, imageUrl, image, getImageUrl, getImage, revokeUrl, setCached])

  const compToRender = () => {
    if (!inView || !image)
      return <Skeleton variant='rectangular' height='inherit' width='299px' animation='wave' />
    else
      return (
        <img
          src={image}
          style={style}
          ref={setRef}
          height='90%'
          width='95%'
          alt='an nft'
        />
      )
  }

  return <div ref={ref}>{compToRender()}</div>
}

export default LazyLoadImg
