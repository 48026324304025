import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useRecoilState, useRecoilValue } from 'recoil'
import { klona } from 'klona'

import {
  currency,
  prodState,
  authState,
  sideBarState,
  isCheckingifSignedIn,
  alertState
} from 'State'
import { AlertStatus, Product } from 'Types'

export const useProductState = () => {
  const [productList, setProductList] = useRecoilState(prodState)

  const setTypes = (newItem: Product | Product[]) => {
    if (Array.isArray(newItem)) {
      newItem.forEach((item, i, arr) => {
        const typedItem: Product = {
          ...item,
          productId: item.productId.toString()
        }
        arr[i] = typedItem
      })

      return newItem
    } else return { ...newItem, productId: newItem.productId.toString() }
  }

  const addProd = (product: Product | Product[]) => {
    setProductList(prev => {
      const newList: Product[] = JSON.parse(JSON.stringify(prev))
      const currProds = newList.map(prod => prod.productId)
      const typedProd = setTypes(product)

      if (Array.isArray(typedProd))
        for (const prod of typedProd) {
          if (!currProds.includes(prod.productId)) newList.push(prod)
        }
      else if (!currProds.includes(typedProd.productId)) newList.push(typedProd)
      return newList
    })
  }

  const addImagesToProd = (images: Blob[], productId: string) => {
    setProductList(prev => {
      const newList: Product[] = klona(prev)
      newList.forEach(prod => {
        if (prod.productId === productId) prod.images.push(...images)
      })
      return newList
    })
  }

  return {
    productList,
    addImagesToProd,
    addProd
  }
}

export const useCurrencyState = () => {
  const curr = useRecoilValue(currency)

  return {
    currency: curr
  }
}

export const useAuthState = () => {
  const [state, setState] = useRecoilState(authState)
  const [isChecking, setIsChecking] = useRecoilState(isCheckingifSignedIn)

  const checkIfSignedIn = async () => {
    setIsChecking(true)
    const auth = getAuth()

    const waitForAuthInit = async () => {
      let unsubscribe: any
      await new Promise<void>(resolve => {
        unsubscribe = onAuthStateChanged(auth, _ => resolve())
      })
      ;(await unsubscribe!)()
    }

    await waitForAuthInit()
    const user = auth.currentUser

    if (!user) {
      setState({ isSignedIn: false })
      setIsChecking(false)
      return
    }
    const idToken = await getAuth().currentUser?.getIdToken(true)
    // REPLACE THIS
    // const res = await get<Todo[]>(`/todo`, {
    //   headers: { authorization: `Bearer: ${idToken}` },
    // });

    // todoSetState(res.data);
    setIsChecking(false)
    setState({ isSignedIn: true, idToken })
  }

  return { authState: state, isChecking, checkIfSignedIn }
}

export const useSideBarState = () => {
  const [state, setState] = useRecoilState(sideBarState)

  const toggleSideBar = () => setState(prev => !prev)

  return { sideBarState: state, toggleSideBar }
}

export const useAlertState = () => {
  const [state, setState] = useRecoilState(alertState)

  const clearAlert = () =>
    setState({
      open: false,
      status: {
        msg: '',
        severity: 'success'
      }
    })

  const setAlert = (status: AlertStatus) =>
    setState({
      open: true,
      status
    })

  return { alertState: state, clearAlert, setAlert }
}

export const useLocalStorage = () => {

  const setEventVersion = (eventName: string, version: number) => {
    localStorage.removeItem(eventName)
    localStorage.setItem(eventName, version.toString())
  }

  const getEventVersion = (eventName: string) => {
    const version = localStorage.getItem(eventName)
    if (!version) return undefined
    return parseInt(version)
  }

  return { setEventVersion, getEventVersion}
}