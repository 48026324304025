import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'

import { Dropdown, TextField } from 'Components/Form'
import { Item, Transaction, VendorItem } from 'Types/Products'

type ProdDialog = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  item?: VendorItem
  onConfirm: () => any
  setTransaction: React.Dispatch<React.SetStateAction<Transaction>>
  updateCb: (
    item: VendorItem,
    setItem: React.Dispatch<React.SetStateAction<VendorItem | undefined>>
  ) => Promise<void>
  editItem:
    | {
        item: Item
        index: number
      }
    | undefined
  setEditItem: React.Dispatch<
    React.SetStateAction<
      | {
          item: Item
          index: number
        }
      | undefined
    >
  >
}

type DefaultValues = {
  variation?: string
  altPrice?: string
  quantity: number
  note: string
}

const ProductDialog: React.FC<ProdDialog> = ({
  open,
  setOpen,
  loading,
  item,
  onConfirm,
  setTransaction,
  editItem,
  setEditItem,
  updateCb
}) => {
  if (!item) setOpen(false)
  const [vendorItem, setVendorItem] = React.useState<VendorItem | undefined>(item)

  React.useEffect(() => {
    if (!vendorItem || !item || vendorItem.id !== item.id) {
      setVendorItem(item)
    }
  }, [vendorItem, setVendorItem, item])

  // Cant figure out why the variation is being set as undefined on first page load.
  const defaultValues: DefaultValues = React.useMemo(
    () => ({
      variation: vendorItem?.variations[0].variationId,
      altPrice: '',
      quantity: 1,
      note: ''
    }),
    [vendorItem?.variations]
  )

  const { control, reset, handleSubmit, watch, setError } = useForm({ defaultValues })
  const onSubmit: SubmitHandler<any> = data => {
    if (!vendorItem) return null
    const variation = vendorItem?.variations.find(
      variation => variation.variationId === data.variation
    )

    if (!variation) return null

    console.log({ variation })
    const { variationId, variationName, variationPrice, variationPricingType } = variation

    if (variationPricingType === 'VARIABLE_PRICING' && !data.altPrice) {
      setError('altPrice', { message: 'Variable selection requires Alt Price' })
      return null
    }

    if (editItem?.item) {
      setTransaction(prev => {
        const newTransactions: Transaction = JSON.parse(JSON.stringify(prev))
        const newItems = newTransactions.items.map((item, index) => {
          if (index === editItem.index) {
            item.variationId = variationId
            item.variationName = variationName
            item.variationPrice = variationPrice?.amount || '0'
            item.alternativePrice = data.altPrice
            item.quantity = data.quantity
            item.note = data.note
          }
          return item
        })
        newTransactions.items = newItems
        return newTransactions
      })
    } else {
      const item: Item = {
        itemId: vendorItem.id,
        itemName: vendorItem.name,
        variationId,
        variationName,
        variationPrice: variationPrice?.amount || '0',
        alternativePrice: data.altPrice,
        quantity: data.quantity,
        note: data.note
      }

      setTransaction(prev => {
        const transactions: Transaction = JSON.parse(JSON.stringify(prev))
        transactions.items.push(item)
        return transactions
      })
    }
    setEditItem(undefined)
    setOpen(false)
  }

  React.useEffect(() => {
    if (editItem) {
      console.log('test', { editItem })
      defaultValues.variation = editItem.item.variationId
      defaultValues.quantity = editItem.item.quantity
      defaultValues.altPrice = editItem.item.alternativePrice
      defaultValues.note = editItem.item.note
      reset(defaultValues)
    } else if (vendorItem) {
      defaultValues.variation = vendorItem.variations[0].variationId
      reset(defaultValues)
    }
  }, [vendorItem, reset, editItem, defaultValues])

  const getItemTotal = () => {
    const selectedItems = watch()
    const variation = vendorItem?.variations.find(
      variation => variation.variationId === selectedItems.variation
    )
    const variationPrice = variation?.variationPrice?.amount || '0'
    if (!variationPrice) return ''
    const selectedPrice = selectedItems.altPrice || +variationPrice / 100
    return `$${selectedPrice} x ${selectedItems.quantity} = $${
      +selectedPrice * selectedItems.quantity
    } ${selectedItems.altPrice && '*** Using Alt Price'}`
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        reset({
          variation: vendorItem?.variations[0].variationId,
          altPrice: '',
          quantity: 1,
          note: ''
        })
        setOpen(false)
        setEditItem(undefined)
      }}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
    >
      <DialogTitle
        id='alert-dialog-title'
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        {vendorItem?.name}
        <Button
          variant='contained'
          onClick={() => vendorItem && updateCb(vendorItem, setVendorItem)}
        >
          Update Item
        </Button>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {vendorItem?.name + ': Choose a Variation.'}
          </DialogContentText>
          <Controller
            control={control}
            name='variation'
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <RadioGroup {...field}>
                  {vendorItem &&
                    vendorItem?.variations.map(variation => (
                      <div
                        key={variation.variationId}
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <FormControlLabel
                            value={variation.variationId}
                            control={<Radio />}
                            label={''}
                          />
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%'
                            }}
                          >
                            <Typography>{variation.variationName}</Typography>
                            {variation.variationPricingType === 'FIXED_PRICING' &&
                            variation.variationPrice ? (
                              <Typography>
                                {'$' + +variation.variationPrice.amount / 100}
                              </Typography>
                            ) : (
                              'Variable'
                            )}
                          </div>
                        </div>
                        <Divider flexItem />
                      </div>
                    ))}
                </RadioGroup>
              )
            }}
          />
          <Grid container alignItems={'flex-end'} gap={2}>
            <Dropdown
              control={control}
              fieldName='quantity'
              label={'Quantity'}
              selectItems={Array.from({ length: 100 }, (_, index) => ({ value: `${++index}` }))}
              gridProps={{
                xs: 5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%'
              }}
              disableSpacer
              style={{ width: '100%', justifyContent: 'center' }}
            />
            <TextField
              control={control}
              fieldName='altPrice'
              label='Alternative Price'
              inputProps={{ inputMode: 'numeric' }}
              type='number'
              gridProps={{ xs: 6 }}
              InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
            />
            <TextField
              control={control}
              fieldName='note'
              label='Extra Notes'
              fullWidth
              multiline
              gridProps={{ xs: 12 }}
            />
            <Grid item xs={12}>
              <Typography>Item Total: {getItemTotal()}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant='contained'
            type='submit'
            color='primary'
            disabled={loading}
            onClick={onConfirm}
            autoFocus
          >
            {editItem ? 'Update Cart' : 'Add to Cart'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default ProductDialog
