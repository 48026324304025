import * as React from 'react'
import { Grid, Button, useTheme } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useRecoilValue } from 'recoil'

import BasicDropdown from 'Components/BasicDropdown'
import IconButton from 'Components/IconButton'
import styles from './styles'
import { FooterProps, SelectItems } from 'Types'
import { eventTicketFormState } from 'State'

const FormFooter: React.FC<FooterProps> = ({ insert, index, defaultSections }) => {
  const eventFormState = useRecoilValue(eventTicketFormState)
  const [sectionNames, setSectionNames] = React.useState<SelectItems[]>(
    defaultSections
      .reduce(
        (accum, section) => [...accum, { value: section.type }],
        [{ value: '' }]
        // Not sure how to get it to work with an empty array so I dont have to remove the first array value
      )
      .slice(1)
  )
  const [state, setState] = React.useState<string>(sectionNames[0].value)
  const [isClicked, setIsClicked] = React.useState(false)
  const { transitions } = useTheme()

  const handleClick = () => {
    setIsClicked(false)
    const section = defaultSections.find(section => section.type === state)
    if (section) return insert(index + 1, section)
  }

  React.useEffect(() => {
    if (!eventFormState) setSectionNames(prev => [...prev, { value: 'eventTicket' }])
    else if (eventFormState)
      setSectionNames(prev => {
        const newState = [...prev]
        newState.splice(newState.findIndex(({ value }) => value === 'eventTicket', 1))
        return newState
      })
  }, [eventFormState])

  return (
    <Grid
      item
      xs={12}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={styles.footer}
    >
      <IconButton
        tooltip={!isClicked ? 'Add new section here' : 'Cancel'}
        onClick={() => setIsClicked(prev => !prev)}
      >
        <Add sx={styles.addButton(isClicked, transitions)} />
      </IconButton>
      {isClicked ? (
        <div>
          <BasicDropdown
            label='New Section'
            selectItems={sectionNames}
            setState={setState}
            defaultValue={sectionNames[0].value}
          />
          <Button variant='contained' sx={{ margin: '1em' }} onClick={handleClick}>
            Add Form
          </Button>
        </div>
      ) : null}
    </Grid>
  )
}

export default FormFooter
