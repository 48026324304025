import * as React from 'react'

import { Controller } from 'react-hook-form'

import { FreeSolo as FreeSoloAutocomplete } from 'Components/Autocomplete'
import gridWrapper from 'Utils/GridWrapper'
import { FreeSoloFormProps } from 'Types'

const FormFreeSolo: React.FC<FreeSoloFormProps> = ({
  fieldName,
  label,
  placeholder,
  control,
  controllerProps,
  options,
  sx,
  onChange,
  setValue
}) => {
  return (
    <Controller
      name={fieldName}
      {...controllerProps}
      render={({ field }) => (
        <FreeSoloAutocomplete
          defaultValue={field.value}
          label={label}
          placeholder={placeholder}
          options={options}
          onChange={value => {
            if (setValue) {
              field.onChange(value[setValue])
              if (onChange) onChange(value)
            } else {
              field.onChange(value)
              if (onChange) onChange(value)
            }
          }}
          sx={sx}
        />
      )}
      control={control}
    />
  )
}

export const FreeSolo = ({
  disableGrid,
  disableSpacer,
  gridProps,
  spacerProps,
  ...props
}: FreeSoloFormProps) => {
  return gridWrapper<FreeSoloFormProps>({
    disableGrid,
    disableSpacer,
    Component: FormFreeSolo,
    props,
    gridProps,
    spacerProps,
    key: props.fieldName
  })
}

export default FreeSolo
