import * as React from 'react'
import { Grid, Theme } from '@mui/material'
import 'react-medium-image-zoom/dist/styles.css'
import { useLocation } from 'react-router-dom'

import { mapping, mapEventItems } from './helpers'

import { useAxios } from 'Hooks'
import { EventPageItemProps, BackendEvent, Vendor } from 'Types'

type Props = {}

type Details = {
  name: string
  urlPrefix: string
  bg: {
    full?: string
    mobile?: string
    colour?: string
  }
}

const EventPage: React.FC<Props> = () => {
  const [pageItems, setPageItems] = React.useState<EventPageItemProps>()
  const [details, setDetails] = React.useState<Details>()
  const [eventName, setEventName] = React.useState<string>()
  const { get } = useAxios()

  const location = useLocation()

  const styles = {
    container: (theme: Theme) => ({
      backgroundColor: details && details.bg.colour,
      [theme.breakpoints.down('md')]: details?.bg.mobile && {
        backgroundImage: details && `url(${details?.bg.mobile})`,
        backgroundSize: 'contain repeat-y'
      },
      [theme.breakpoints.up('md')]: details?.bg.full && {
        background: details && `url(${details?.bg.full})`,
        backgroundSize: 'contain'
      }
    })
  }

  React.useEffect(() => {
    if (!eventName) setEventName(location.pathname.split('events/')[1])

    const getEvent = async () => {
      try {
        const event = await get<BackendEvent<Vendor, string>>(`events/${eventName}`)
        const { eventData, sections, urlName } = event.data
        console.log({ event })
        if (!urlName || !sections || !eventData) throw new Error('Issue with loading Data')

        const eventPageItems = mapEventItems(event.data, () => {})

        if (!pageItems) setPageItems(eventPageItems as EventPageItemProps)
        // @ts-ignore
        if (!details) setDetails({ urlName, urlPrefix, bg })
      } catch (e) {
        console.log(e)
      }
    }

    if (!pageItems && eventName) getEvent()
  }, [location, get, pageItems, setPageItems, details, eventName])

  return (
    <Grid container spacing={2} justifyContent='center' sx={styles.container}>
      {pageItems &&
        pageItems.map((item, i) => {
          const Component = mapping[item.type]
          // @ts-ignore this is kinda sketchy but the typing should be keeping it secure enough
          return <Component key={item.type + '-' + i} {...item.props} />
        })}
    </Grid>
  )
}

export default EventPage
