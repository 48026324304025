import * as React from 'react'

import { TextField } from 'Components/Form'
import { EventFormProps } from 'Types'
import styles from './styles'
import FormContainer from './FormContainer'

const MapForm: React.FC<EventFormProps> = ({ control, index, ...rest }) => {
  return (
    <FormContainer index={index} {...rest}>
      <TextField
        control={control}
        fieldName={`sections.${index}.lat`}
        label={'Map Latitude'}
        disableSpacer
        gridProps={{ xs: 6, md: 3 }}
        sx={styles.item}
      />
      <TextField
        control={control}
        fieldName={`sections.${index}.lng`}
        label={'Map Longitude'}
        disableSpacer
        gridProps={{ xs: 6, md: 3 }}
        sx={styles.item}
      />
      <TextField
        control={control}
        fieldName={`sections.${index}.markerText`}
        label={'Map Text Marker'}
        disableSpacer
        gridProps={{ xs: 12 }}
        sx={styles.item}
      />
    </FormContainer>
  )
}

export default MapForm
