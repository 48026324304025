import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useForm } from 'react-hook-form'

import { TextField } from 'Components/Form'
import { Item, Transaction } from 'Types/Products'

type CCheckoutDialog = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  onConfirm: (tender: number, cb: () => void) => any
  transaction: Transaction
  setTransaction: React.Dispatch<React.SetStateAction<Transaction>>
  setEditItem: React.Dispatch<
    React.SetStateAction<
      | {
          item: Item
          index: number
        }
      | undefined
    >
  >
}

const CashCheckoutDialog: React.FC<CCheckoutDialog> = ({
  open,
  setOpen,
  loading,
  onConfirm,
  transaction,
  setTransaction,
  setEditItem
}) => {
  const getItemCount = () => transaction.items.reduce((acc, curr) => acc + +curr.quantity, 0)
  const getTotalAmount = () =>
    transaction.items.reduce((acc, curr) => {
      const currPrice = +curr.alternativePrice || +curr.variationPrice / 100
      const itemTotal = currPrice * +curr.quantity

      return acc + itemTotal
    }, 0)

  const getItemSubTotal = (item: Item) => {
    const itemPrice = +item.alternativePrice || +item.variationPrice / 100
    return itemPrice * item.quantity
  }

  const removeTransaction = (index: number) => {
    setTransaction(prev => {
      const newTransactions: Transaction = JSON.parse(JSON.stringify(prev))
      newTransactions.items.splice(index, 1)
      return newTransactions
    })
  }

  const { control, watch, setValue } = useForm({ defaultValues: { tender: 0 } })

  React.useEffect(() => {
    // No idea why setting tender in defaultvalues doesnt work but this does
    setValue('tender', transaction.tender)
  }, [transaction.tender, setValue])
  const tender = watch('tender')

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
    >
      <DialogTitle id='alert-dialog-title'>Checkout</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Confirm or Edit Cart Selection. {getItemCount()} Items Total.
        </DialogContentText>
        <List dense>
          {transaction.items.map((item, i) => (
            <ListItemButton
              key={item.variationId + `-${i}`}
              onClick={() => setEditItem({ item, index: i })}
            >
              <ListItem
                secondaryAction={
                  <IconButton
                    edge='end'
                    onClick={event => {
                      event.stopPropagation()
                      removeTransaction(i)
                    }}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={item.itemName}
                  secondary={
                    item.variationName +
                    ` x ${item.quantity} x  $${
                      item.alternativePrice
                        ? +item.alternativePrice + ' (Alt Price) '
                        : +(+item.variationPrice) / 100
                    } = $${getItemSubTotal(item)}`
                  }
                />
              </ListItem>
            </ListItemButton>
          ))}
        </List>
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item xs={8} gap={2}>
            <Typography variant='h5'>Cart Total: ${getTotalAmount()}</Typography>
          </Grid>
          <TextField
            control={control}
            fieldName='tender'
            label='Tender'
            disableSpacer
            gridProps={{ xs: 3 }}
            inputProps={{ inputMode: 'numeric' }}
            type='number'
          />
          <Typography>
            Customer Tender: ${tender} - ${getTotalAmount()} = ${+tender - getTotalAmount()}
          </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='error' onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant='contained'
          type='submit'
          color='primary'
          // No idea why 0 doesnt work with just 0
          disabled={!transaction.items.length || +tender - getTotalAmount() <= -0.01 || loading}
          onClick={() => onConfirm(+tender, () => setValue('tender', 0))}
          autoFocus
        >
          {loading ? 'Loading' : 'Confirm Checkout'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CashCheckoutDialog
