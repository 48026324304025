import * as React from 'react'
import { Grid } from '@mui/material'

import { BasicUpload, TextField } from 'Components/Form'
import { EventFormProps } from 'Types'
import styles from './styles'
import FormContainer from './FormContainer'

const BannerForm: React.FC<EventFormProps> = ({ control, index, ...rest }) => {
  const toolTip =
    'This will be the Top Banner of the event, It will show up on the sites front page carousel, event page carousel, and at the top of the event page, best size is around 3000 x 300'

  return (
    <FormContainer index={index} toolTip={toolTip} {...rest}>
      <Grid item sx={styles.item} xs={11} md={6}>
        <BasicUpload
          control={control}
          fieldName={`sections.${index}.image`}
          multiple={false}
          label={'Banner'}
        />
      </Grid>
      <TextField
        control={control}
        fieldName={`sections.${index}.alt`}
        label={'Banner Alt Message'}
        gridProps={{ xs: 12, md: 6 }}
        sx={{ width: '80%' }}
        disableSpacer
      />
    </FormContainer>
  )
}

export default BannerForm
