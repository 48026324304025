import * as React from 'react'
import { Box } from '@mui/material'

import SignInForm from './SignIn'

type Props = {}

const styles = {
  container: {
    justifyContent: 'center',
    width: 'inherit'
  }
}

const SignIn: React.FC<Props> = () => {
  return (
    <Box sx={styles.container}>
      <SignInForm />
    </Box>
  )
}

export default SignIn
