import * as React from 'react'
import { Grid } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { useFieldArray } from 'react-hook-form'

import { DatePicker, TextField } from 'Components/Form'
import FormContainer from './FormContainer'
import IconButton from 'Components/IconButton'
import { HeaderV2 } from '.'
import { EventFormProps } from 'Types'
import styles from './styles'

const TitleForm: React.FC<EventFormProps> = ({ control, index, ...rest }) => {
  const {
    fields,
    append: creatorAppend,
    remove: creatorRemove
  } = useFieldArray({ control, name: `sections.${index}.creators` })

  return (
    <FormContainer index={index} {...rest}>
      <DatePicker
        control={control}
        fieldName={`sections[${index}].date`}
        label={'Event Date'}
        gridProps={{ xs: 8, md: 3 }}
        disableSpacer
        sx={styles.item}
      />
      <TextField
        control={control}
        fieldName={`sections[${index}].name`}
        label={'Event Name'}
        gridProps={{ xs: 12, md: 9 }}
        sx={styles.item}
      />
      <TextField
        control={control}
        fieldName={`sections[${index}].description`}
        label={'Event Description'}
        gridProps={{ xs: 12 }}
        sx={styles.item}
        multiline
      />
      <HeaderV2 title={'Creators'} variant='h6' index={index} disableDrag {...rest} />
      {fields.map(({ id }, i) => (
        <Grid key={id} item sx={styles.item} xs={12} md={3}>
          <TextField
            control={control}
            fieldName={`sections[${index}].creators[${i}].name`}
            label={'Name'}
            disableGrid
            disableSpacer
            sx={{ marginBottom: '1em', width: '100%' }}
          />
          <TextField
            control={control}
            fieldName={`sections[${index}].creators[${i}].url`}
            label={'URL'}
            sx={{ width: '100%' }}
            disableGrid
            disableSpacer
          />
          <IconButton tooltip='Delete Creator' onClick={() => creatorRemove(i)}>
            <Delete />
          </IconButton>
        </Grid>
      ))}
      <Grid item xs={1}>
        <IconButton tooltip='Add Creator' onClick={() => creatorAppend({ name: '', url: '' })}>
          <Add />
        </IconButton>
      </Grid>
    </FormContainer>
  )
}

export default TitleForm
