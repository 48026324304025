import * as React from 'react'
import { Grid, GridProps } from '@mui/material'
import { useSortable } from '@dnd-kit/sortable'

import HeaderV2 from './HeaderV2'
import { EventTypes, FooterProps, HeaderV2Props } from 'Types'
import { mapTypestoTitle } from 'Views/NewEvent/helpers'
import styles from './styles'
import FormFooter from './Footer'

type Props = {
  id: string
  type: EventTypes
  activeId: string | undefined
  gridProps?: GridProps
} & Omit<HeaderV2Props, 'title' | 'listeners' | 'attributes' | 'Hide' | 'setHide'> &
  Omit<FooterProps, 'index'>

const FormContainer: React.FC<Props> = ({ id, type, activeId, children, gridProps, ...rest }) => {
  const [hide, setHide] = React.useState<{ state: boolean; prevState: boolean }>({
    state: false,
    prevState: false
  })

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id
  })

  React.useEffect(() => {
    if (activeId === id) setHide(prev => ({ state: true, prevState: prev.state }))
    else setHide(prev => ({ state: prev.prevState, prevState: prev.state }))
  }, [activeId, id, setHide])

  return (
    <Grid
    {...gridProps}
      item
      container
      rowSpacing={2}
      columnSpacing={1}
      ref={setNodeRef}
      sx={styles.container(transform, transition, isDragging)}
    >
      <HeaderV2
        title={mapTypestoTitle(type)}
        attributes={attributes}
        listeners={listeners}
        hide={hide.state}
        setHide={setHide}
        {...rest}
      />
      {!hide.state ? children : undefined}
      <FormFooter {...rest} />
    </Grid>
  )
}

export default FormContainer
