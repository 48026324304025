import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import ConfirmDialog from 'Components/ConfirmDialog'
import SnackAlert from 'Components/SnackAlert'
import { useAlertState, useAxios } from 'Hooks'
import { CashEvent } from 'Types/Products'
import * as React from 'react'
import { Link } from 'react-router-dom'

type Props = {}

type VendorEvent = {
  id: string
  updatedAt: string
  version: string
  name: string
}

const styles = {
  link: {
    textDecoration: 'none',
    color: 'black'
  }
}

const Cash: React.FC<Props> = () => {
  const { get, post } = useAxios()
  const [events, setEvents] = React.useState<VendorEvent[]>([])
  const [cashEvents, setCashEvents] = React.useState<CashEvent[]>([])
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { setAlert } = useAlertState()

  const getCashEvents = React.useCallback(async () => {
    console.log('getting cash events')
    const { data } = await get<CashEvent[]>('admin/inventory/cashEvents')
    setCashEvents(data)
  }, [get])

  React.useEffect(() => {
    if (!cashEvents.length) {
      getCashEvents()
    }
  }, [cashEvents.length, getCashEvents])

  const getEventDates = (cashEvent: CashEvent) => {
    if (!cashEvent.transactions.length) return ''
    const eventDates = [] as string[]

    let currentDay = ''

    const sortedTransactions = cashEvent.transactions.sort((a, b) => {
      return new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime()
    })

    sortedTransactions.forEach(transaction => {
      if (!transaction.createdAt) return
      let day = new Date(transaction.createdAt).toLocaleDateString('default', { day: 'numeric' })
      if (day !== currentDay) {
        eventDates.push(transaction.createdAt)
        currentDay = day
      }
    })

    if (eventDates.length === 1) return new Date(eventDates[0]).toDateString()
    else {
      const firstDate = new Date(eventDates[0]).toLocaleDateString('default', {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
      })
      const lastDate = new Date(eventDates[eventDates.length - 1]).toLocaleDateString('default', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })
      return `${firstDate} - ${lastDate}`
    }
  }

  const dialogTitle = 'Create Cash Event'
  const dialogDescription = 'Would you like to create a cash tracker for this event?'
  const confirmButtonText = 'Create Cash Event.'

  return (
    <Grid container sx={{ marginTop: '3em' }} gap={2}>
      <Grid item xs={12} md={3} display={'flex'} justifyContent={'center'}>
        <Button
          variant='contained'
          sx={{ height: '4em' }}
          onClick={async () => {
            try {
              setLoading(true)
              const { data } = await get<VendorEvent[]>('admin/inventory/events')
              setEvents(data)
            } catch (e) {
              console.log(e)
            } finally {
              setLoading(false)
            }
          }}
        >
          Search for Events
        </Button>
      </Grid>
      <Grid item xs={12} md={8} display={'flex'} flexDirection={'column'} gap={2}>
        {events.length ? (
          events.map(event => (
            <Card key={event.id}>
              <CardActionArea onClick={() => setOpen(true)}>
                <CardContent>
                  <Typography>{event.name}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))
        ) : loading ? (
          <Grid item xs={12} display={'flex'} flexDirection='row' justifyContent={'center'} gap={3}>
            <CircularProgress />
            <Typography variant='h5'>Loading</Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        sx={{ marginTop: '3em' }}
      >
        <Typography variant='h3'>Cash Events</Typography>
        {cashEvents.length ? (
          cashEvents
            .slice()
            .sort(
              (a, b) =>
                new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime()
            )
            .map(event => (
              <Card key={event._id} sx={{ backgroundColor: 'ghostwhite' }}>
                <Link to={`/cash/drawer/${event._id}`} style={styles.link}>
                  <CardActionArea>
                    <CardContent>
                      <Typography
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          paddingBottom: '1em'
                        }}
                      >
                        {event.name}
                      </Typography>
                      <Divider />
                      <Grid container sx={{ paddingTop: '0.5em' }}>
                        <Grid item xs={12} md={4}>
                          <Typography>{getEventDates(event)}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography>Transactions: {event.transactions.length}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography>
                            Sales: $
                            {event.transactions.reduce(
                              (acc, curr) => acc + +curr.transactionTotal,
                              0
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            ))
        ) : (
          <Grid item xs={12} display={'flex'} flexDirection='row' justifyContent={'center'} gap={3}>
            <CircularProgress />
            <Typography variant='h5'>Loading</Typography>
          </Grid>
        )}
      </Grid>
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        loading={false}
        onConfirm={async () => {
          try {
            const { data } = await post('admin/inventory/event', events[0])
            console.log({ data })
            getCashEvents()
            setAlert({ msg: `Created Cash Event!`, severity: 'success' })
            setOpen(false)
          } catch (e) {
            console.log(e)
            setAlert({ msg: `Failed to Create Cash Event!`, severity: 'error' })
          }
        }}
        title={dialogTitle}
        description={dialogDescription}
        confirmButtonText={confirmButtonText}
        confirmColour='primary'
      />
      <SnackAlert />
    </Grid>
  )
}

export default Cash
