import * as React from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy
} from '@dnd-kit/sortable'
import { UseFieldArrayMove } from 'react-hook-form'

type Props = {
  onDragEnd?: (event: DragEndEvent) => any
  move?: UseFieldArrayMove
  items: (UniqueIdentifier | { id: UniqueIdentifier })[]
}

const FormDnD: React.FC<Props> = ({ onDragEnd, move, items, children }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const active = event.active.data.current?.sortable.index
    const over = event.over?.data.current?.sortable.index

    if (active === undefined || over === undefined) return undefined

    if (active !== over) {
      move && move(active, over)
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={move ? handleDragEnd : onDragEnd}
    >
      {/* Not sure about fields here */}
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  )
}

export default FormDnD
