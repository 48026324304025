import { Delete, ExpandMore } from '@mui/icons-material'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Pagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery
} from '@mui/material'
import { useAlertState, useAxios } from 'Hooks'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import CashCheckoutDialog from 'Components/CashCheckoutDialog'
import ProductDialog from 'Components/ProductDialog'
import { cashEventState, vendoringEventState } from 'State'
import { CashEvent, Item, Transaction, VendorItem, VendoringEvent } from 'Types/Products'
import ConfirmDialog from 'Components/ConfirmDialog'
import SnackAlert from 'Components/SnackAlert'
import { ConfirmDialogProps } from 'Types'

type Props = {}

const Transactions: React.FC<Props> = () => {
  const [cashEvent, setCashEvent] = useRecoilState(cashEventState)
  const [vendorEvent, setVendorEvent] = useRecoilState(vendoringEventState)
  const [openCart, setOpenCart] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [transaction, setTransaction] = React.useState<Transaction>({
    itemNames: [],
    items: [],
    tender: 0,
    transactionTotal: ''
  })
  const [selTransactions, setSelTransactions] = React.useState<Transaction[]>([])
  const [editItem, setEditItem] = React.useState<{ item: Item; index: number }>()
  const [selItem, setSelItem] = React.useState<VendorItem>()
  const [showSales, setShowSales] = React.useState(false)
  const [dialog, setDialog] = React.useState<Partial<ConfirmDialogProps>>({})
  const [loading, setLoading] = React.useState(false)
  const [currentPage, setCurrentPage] = React.useState(1)
  // Implement a setItemsPerPage Selector
  const [itemsPerPage] = React.useState(5)
  const [toggle, setToggle] = React.useState('All')
  const location = useLocation()
  const eventId = location.pathname.split('transactions/')[1]
  const { get, remove, patch } = useAxios()
  const { setAlert } = useAlertState()
  const matches = useMediaQuery('(min-width:600px)')

  const deleteTransaction = async (transaction: Transaction) => {
    const { data } = await remove<CashEvent>(
      `/admin/inventory/cashEvents/transactions/${eventId}`,
      {
        data: { transaction }
      }
    )
    setCashEvent(data)
  }

  React.useEffect(() => {
    const getEventData = async () => {
      const {
        data: { cashEvent, vendorEvent }
      } = await get<{ cashEvent: CashEvent; vendorEvent: VendoringEvent }>(
        `/admin/inventory/event/${eventId}`
      )
      setCashEvent(cashEvent)
      setVendorEvent(vendorEvent)
      setToggle(
        new Date(
          cashEvent.transactions[cashEvent.transactions.length - 1].createdAt || ''
        ).toDateString()
      )
    }

    if ((!cashEvent && !vendorEvent) || eventId !== cashEvent?._id) {
      getEventData()
    }
  }, [get, cashEvent, setCashEvent, vendorEvent, setVendorEvent, eventId, setToggle])

  React.useEffect(() => {
    if (editItem?.item) {
      console.log({ editItem })
      setSelItem(() => {
        return vendorEvent?.items.find(item => item.id === editItem.item.itemId)
      })
      setOpen(true)
    }
  }, [editItem, setOpen, setSelItem, vendorEvent])

  const getTotalAmount = () =>
    transaction.items.reduce((acc, curr) => {
      const currPrice = +curr.alternativePrice || +curr.variationPrice / 100
      const itemTotal = currPrice * +curr.quantity

      return acc + itemTotal
    }, 0)

  const getTotalSales = () => {
    const totalSales = selTransactions.reduce((acc, curr) => acc + +curr.transactionTotal, 0)
    return `Total Sales: $${totalSales}`
  }

  // Deprecated now that each individual day can be viewed
  // const getDailySales = () => {
  //   const saleDays = cashEvent?.transactions.reduce((acc, curr) => {
  //     if (!curr.createdAt) return acc
  //     const date = new Date(curr.createdAt).toLocaleDateString('default', {
  //       weekday: 'short',
  //       month: 'short',
  //       day: 'numeric'
  //     })

  //     const day = acc.find(day => day.day === date)
  //     if (day) {
  //       day.sales += +curr.transactionTotal
  //       return acc
  //     } else {
  //       return [...acc, { day: date, sales: +curr.transactionTotal }]
  //     }
  //   }, [] as { day: string; sales: number }[])

  //   return saleDays?.map(days => `${days.day}: $${days.sales}`).join(', ')
  // }

  const removeTransaction = async (transaction: Transaction) => {
    try {
      setLoading(true)
      await deleteTransaction(transaction)
      setAlert({ msg: `Deleted Transaction!`, severity: 'success' })
      setOpenConfirm(false)
      setTransaction({ itemNames: [], items: [], tender: 0, transactionTotal: '' })
    } catch (e) {
      console.log(e)
      setAlert({ msg: `Failed to Delete Transaction!`, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const updateVendorItem = async (
    item: VendorItem,
    setItem: React.Dispatch<React.SetStateAction<VendorItem | undefined>>
  ) => {
    setOpenConfirm(true)
    setDialog({
      description: 'Would you like to update this Listing to match square?',
      confirmButtonText: 'Update Listing',
      confirmColour: 'primary',
      onConfirm: async () => {
        try {
          setLoading(true)
          const { data } = await patch<VendoringEvent>(
            `/admin/inventory/event/${vendorEvent?.id}/${item.id}/${item._id}`,
            {}
          )
          setItem(data.items.find(newItem => newItem.id === item.id))
          setVendorEvent(data)
          setAlert({ msg: 'Successfully updated Vendor Item!', severity: 'success' })
          setOpenConfirm(false)
        } catch (e) {
          console.log(e)
          setAlert({ msg: 'Failed to update Vendor Item.', severity: 'error' })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  type ItemSummary = {
    itemId: string
    itemName: string
    variations: {
      variationId: string
      variationName: string
      variationPrice: string
      quantity: number
    }[]
  }
  const determineSalesQuantity = () => {
    const ItemSummary: ItemSummary[] = []

    selTransactions.forEach(transaction => {
      transaction.items.forEach(item => {
        const foundItem = ItemSummary.find(i => i.itemId === item.itemId)
        if (foundItem) {
          const foundVariation = foundItem.variations.find(v => v.variationId === item.variationId)
          if (foundVariation) {
            foundVariation.quantity += item.quantity
          } else {
            foundItem.variations.push({
              variationId: item.variationId,
              variationName: item.variationName,
              variationPrice: item.variationPrice,
              quantity: item.quantity
            })
          }
        } else {
          ItemSummary.push({
            itemId: item.itemId,
            itemName: item.itemName,
            variations: [
              {
                variationId: item.variationId,
                variationName: item.variationName,
                variationPrice: item.variationPrice,
                quantity: item.quantity
              }
            ]
          })
        }
      })
    })
    const items: Item[] = []
    selTransactions.forEach(transaction => {
      transaction.items.forEach(item => {
        const foundItem = items.find(i => i.variationId === item.variationId)
        if (foundItem) {
          foundItem.quantity += item.quantity
        } else {
          items.push({ ...item, quantity: item.quantity })
        }
      })
    })

    const groupedSales = ItemSummary.reduce((acc, item) => {
      const itemId = item.itemId
      // const salesValue = item.quantity * (+item.variationPrice / 100)
      const salesValue = item.variations.reduce((acc, variation) => {
        return acc + variation.quantity * (+variation.variationPrice / 100)
      }, 0)

      acc[itemId] = (acc[itemId] || 0) + salesValue
      return acc
    }, {} as { [key: string]: number })

    ItemSummary.sort((a, b) => {
      const salesValueA = groupedSales[a.itemId]
      const salesValueB = groupedSales[b.itemId]

      if (salesValueA > salesValueB) {
        return -1
      } else if (salesValueA < salesValueB) {
        return 1
      }

      return a.itemId.localeCompare(b.itemId)
    })
    return ItemSummary
  }

  const getDates = (cashEvent: CashEvent) => {
    const eventDates = [] as string[]

    let currentDay = ''

    const sortedTransactions = cashEvent.transactions
      .slice()
      .sort((a, b) => new Date(a.createdAt || '').getTime() - new Date(b.createdAt || '').getTime())

    sortedTransactions.forEach(transaction => {
      if (!transaction.createdAt) return
      let day = new Date(transaction.createdAt).toLocaleDateString('default', { day: 'numeric' })
      if (day !== currentDay) {
        eventDates.push(transaction.createdAt)
        currentDay = day
      }
    })
    return eventDates
  }

  const setTransactions = React.useCallback(
    (cashEvent: CashEvent) => {
      const transactions = [] as Transaction[]

      if (toggle === 'All') {
        transactions.push(...cashEvent.transactions)
      } else {
        cashEvent.transactions.forEach(transaction => {
          if (new Date(transaction.createdAt || '').toDateString() === toggle) {
            transactions.push(transaction)
          }
        })
      }

      setSelTransactions(transactions)
    },
    [toggle]
  )

  React.useEffect(() => {
    if (cashEvent) {
      setTransactions(cashEvent)
    }
  }, [toggle, cashEvent, setTransactions])

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        mt={'1em'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ToggleButtonGroup
          color='primary'
          value={toggle}
          exclusive
          onChange={(_, newToggle) => {
            if (newToggle === 'All') setToggle(newToggle)
            else setToggle(new Date(newToggle).toDateString())
          }}
          aria-label='Platform'
          orientation={`${matches ? `horizontal` : `vertical`}`}
        >
          {cashEvent &&
            getDates(cashEvent)?.map(date => (
              <ToggleButton key={date} value={new Date(date).toDateString()}>
                {new Date(date).toDateString()}
              </ToggleButton>
            ))}
          <ToggleButton value={'All'}>All Days</ToggleButton>
          <Button variant='contained' onClick={() => setShowSales(prev => !prev)}>
            {showSales ? 'Hide Total Sales' : 'Show Total Sales'}
          </Button>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h5'>{showSales && ` ${getTotalSales()}`}</Typography>
      </Grid>
      <Typography variant='h4' sx={{ mt: '1em' }}>
        {`Transaction History (${selTransactions.length}) :`}
      </Typography>
      <Grid item xs={12}>
        <List dense>
          {selTransactions
            .slice(
              (currentPage - 1) * itemsPerPage,
              (currentPage - 1) * itemsPerPage + itemsPerPage
            )
            .map(transaction => (
              <div key={transaction.createdAt}>
                <ListItemButton
                  sx={{ pl: 0 }}
                  onClick={() => {
                    setOpenCart(true)
                    setTransaction(transaction)
                  }}
                >
                  <ListItem
                    sx={{ pl: 0 }}
                    secondaryAction={
                      <IconButton
                        size='large'
                        edge='end'
                        onClick={event => {
                          event.stopPropagation()
                          setTransaction(transaction)
                          setDialog({
                            description: 'Are you sure want to Delete this Transaction.',
                            onConfirm: () => removeTransaction(transaction)
                          })
                          setOpenConfirm(true)
                        }}
                      >
                        <Delete />
                      </IconButton>
                    }
                  >
                    <Grid container>
                      <Grid item xs={3}>
                        <Typography variant='h5'>{`$${transaction.transactionTotal}`}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        display={'flex'}
                        justifyContent={'end'}
                        alignItems={'flex-end'}
                      >
                        <Typography variant='body1'>
                          {`${
                            transaction.createdAt &&
                            new Date(transaction.createdAt).toLocaleDateString('default', {
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric'
                            })
                          }`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='body1'>
                          {transaction.items
                            .map(item => `${item.itemName} x ${item.quantity}`)
                            .join()}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                </ListItemButton>
                <Divider />
              </div>
            ))}
          <Pagination
            count={Math.ceil((selTransactions.length || 1) / itemsPerPage)}
            onChange={(_, page) => setCurrentPage(page)}
          />
        </List>

        <Typography variant='h4' sx={{ width: 'fit-content', mt: '2em' }}>
          Top Items:
        </Typography>
        <List dense>
          {determineSalesQuantity().map(item => (
            <div key={`${item.itemId}-${item.variations[0].variationId}`}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls='panel1-content'
                  id='panel1-header'
                >
                  <Grid container>
                    <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                      <Typography variant='h5'>{item.itemName}</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant='h5'>
                        Quantity: {item.variations.reduce((acc, vari) => acc + vari.quantity, 0)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant='h5'>
                        Total: $
                        {item.variations.reduce((acc, variation) => {
                          return acc + variation.quantity * (+variation.variationPrice / 100)
                        }, 0)}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  {item.variations.map(variation => (
                    <Grid container key={variation.variationId} sx={{ mt: '0.25em' }}>
                      <Grid item xs={12} md={4}>
                        <Typography variant='body1' sx={{ width: '100%' }}>
                          {variation.variationName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography
                          variant='body1'
                          sx={{ width: '100%' }}
                        >{`Quantity: ${variation.quantity}`}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant='body1' sx={{ width: '100%' }}>
                          {`Sub Total: $${(variation.quantity * +variation.variationPrice) / 100}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </List>
      </Grid>
      <CashCheckoutDialog
        open={openCart}
        setOpen={setOpenCart}
        loading={loading}
        onConfirm={async (tender, cb) => {
          try {
            setLoading(true)
            const newTransaction: Transaction = {
              ...transaction,
              transactionTotal: getTotalAmount().toString(),
              itemNames: transaction.items.map(item => item.itemName),
              tender
            }

            console.log({ newTransaction })
            const { data } = await patch<CashEvent>(
              `/admin/inventory/cashEvents/transactions/${cashEvent?._id}`,
              {
                transaction: newTransaction
              }
            )
            console.log('cashEvent', data)
            setOpenCart(false)
            setTransaction({ itemNames: [], items: [], tender: 0, transactionTotal: '' })
            setCashEvent(data)
            cb()
            setAlert({ msg: `Updated Transaction!`, severity: 'success' })
          } catch (e) {
            console.log(e)
            setAlert({ msg: `Failed to update Cash Event!`, severity: 'error' })
          } finally {
            setLoading(false)
          }
        }}
        setTransaction={setTransaction}
        transaction={transaction}
        setEditItem={setEditItem}
      />
      <ProductDialog
        open={open}
        setOpen={setOpen}
        loading={loading}
        item={selItem}
        onConfirm={() => {}}
        setTransaction={setTransaction}
        editItem={editItem}
        setEditItem={setEditItem}
        updateCb={updateVendorItem}
      />
      <ConfirmDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        loading={loading}
        {...dialog}
        onConfirm={() => dialog.onConfirm && dialog.onConfirm()}
      />
      <SnackAlert />
    </Grid>
  )
}

export default Transactions
