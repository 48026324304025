import * as React from 'react'
import { Grid } from '@mui/material'
import { Delete, Add, DragHandle } from '@mui/icons-material'
import { useFieldArray } from 'react-hook-form'
import { useSortable } from '@dnd-kit/sortable'

import FormDnD from './FormDND'
import { EmojiTextField } from 'Components/Form'
import IconButton from 'Components/IconButton'
import { EventFormProps, DraggableChild } from 'Types'
import styles from './styles'
import FormContainer from './FormContainer'

const HighlightsItem: React.FC<DraggableChild> = ({ control, id, index, i, itemRemove }) => {
  // id gotta be unique
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id
  })

  return (
    <Grid
      item
      xs={12}
      md={4}
      display={'flex'}
      alignItems={'end'}
      ref={setNodeRef}
      sx={styles.container(transform, transition, isDragging)}
    >
      <EmojiTextField
        control={control}
        fieldName={`sections.${index}.items.${i}.highlight`}
        label={'Highlight'}
        disableGrid
        sx={{ ...styles.item, marginBottom: '0em' }}
        multiline
      />
      <div>
        <IconButton
          tooltip='Move and Drag Highlight'
          {...listeners}
          {...attributes}
          sx={{ cursor: 'move ' }}
        >
          <DragHandle />
        </IconButton>
        <IconButton tooltip='Delete Highlight' onClick={() => itemRemove(i)}>
          <Delete />
        </IconButton>
      </div>
    </Grid>
  )
}

const HighlightsForm: React.FC<EventFormProps> = ({ control, index, ...rest }) => {
  const {
    fields,
    append: itemAppend,
    remove: itemRemove,
    move
  } = useFieldArray({ control, name: `sections.${index}.items` })

  const tooltip =
    'This form is best used to display quick highlights in an easy way to digest, will format into 2 rows of highlights, text only no links. To open the browsers emoji keyboard use cmd ctrl space, for windows use windows key period key or semicolon key'

  return (
    <FormContainer index={index} toolTip={tooltip} {...rest}>
      <FormDnD items={fields} move={move}>
        {fields.map(({ id }, i) => {
          return (
            <HighlightsItem
              key={id}
              id={id}
              control={control}
              i={i}
              index={index}
              itemRemove={itemRemove}
            />
          )
        })}
      </FormDnD>
      <Grid item xs={1}>
        <IconButton tooltip='Add Highlight' onClick={() => itemAppend({ highlight: '' })}>
          <Add />
        </IconButton>
      </Grid>
    </FormContainer>
  )
}

export default HighlightsForm
