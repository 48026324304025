import React from 'react'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'

import NavBar from 'Components/NavBar'
import SideBar from 'Components/SideBar'
import { Dashboard, Product, Products, Login, NewProduct, QrCode, Events, NewEvent } from 'Views'
import './App.css'
import { useAuthState } from 'Hooks/useGlobalState'
import RequireAuth from 'Utils/RequireAuth'
import EventPage from 'Components/EventPage'
import Cash from 'Views/Cash'
import Drawer from 'Views/Cash/drawer'
import Transactions from 'Views/Cash/transactions'

const styles = {
  content: {
    display: 'flex',
    justifySelf: 'center',
    width: '90%',
    '@media (minWidth: 1441px)': {
      width: '80%'
    },
    '@media (minWidth: 1921px)': {
      width: '75%'
    }
  }
}

function App() {
  const { checkIfSignedIn, authState } = useAuthState()

  // Cant figure out how to get rid of eslint dep issue
  React.useEffect(() => {
    checkIfSignedIn()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // React.useEffect(() => console.log({ authState }), [authState])
  return (
    <Router>
      {/* <Suspense fallback={<CircularProgress />}> */}
      <div className={`App`}>
        {/* <ToastContainer {...toastConfiguration} /> */}
        {/* <Global /> */}
        <div className='Header' style={{ zIndex: 1 }}>
          {/* <Suspense fallback={<CircularProgress />}> */}
          <NavBar />
          {authState.isSignedIn && <SideBar />}
          {/* </Suspense> */}
        </div>
        <div className={`Content`} style={styles.content}>
          {/* <Suspense fallback={<CircularProgress />}> */}
          <Routes>
            <Route
              path='/'
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            {/* </Suspense> */}
            <Route path='/login' element={<Login />} />
            {/* <Suspense fallback={<CircularProgress />}> */}
            <Route
              path='/products'
              element={
                <RequireAuth>
                  <Products />
                </RequireAuth>
              }
            />
            <Route
              path='/products/new'
              element={
                <RequireAuth>
                  <NewProduct />
                </RequireAuth>
              }
            />
            <Route
              path='/products/:productId'
              element={
                <RequireAuth>
                  <Product />
                </RequireAuth>
              }
            />
            <Route
              path='/qrcode'
              element={
                <RequireAuth>
                  <QrCode />
                </RequireAuth>
              }
            />
            <Route
              path='/events'
              element={
                <RequireAuth>
                  <Events />
                </RequireAuth>
              }
            />
            <Route
              path='/cash'
              element={
                <RequireAuth>
                  <Cash />
                </RequireAuth>
              }
            />
            <Route
              path='/cash/drawer/:eventId'
              element={
                <RequireAuth>
                  <Drawer />
                </RequireAuth>
              }
            />
            <Route
              path='/cash/drawer/transactions/:eventId'
              element={
                <RequireAuth>
                  <Transactions />
                </RequireAuth>
              }
            />
            <Route
              path='/events/new'
              element={
                <RequireAuth>
                  <NewEvent />
                </RequireAuth>
              }
            />
            <Route
              path='/events/:eventName'
              element={
                <RequireAuth>
                  <EventPage />
                </RequireAuth>
              }
            />
            <Route
              path='/events/edit/:eventName'
              element={
                <RequireAuth>
                  <NewEvent />
                </RequireAuth>
              }
            />
          </Routes>
          {/* </Suspense> */}
        </div>
        {/* <div className='Footer'>
          <Footer />
        </div> */}
      </div>
      {/* </Suspense> */}
    </Router>
  )
}

export default App
