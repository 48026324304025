import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import { useNavigate } from 'react-router-dom'

import useAxios from 'Hooks/useAxios'
import { authState, sideBarState } from 'State'

const useFirebase = () => {
  const [state, setState] = useRecoilState(authState)
  const setSBState = useSetRecoilState(sideBarState)
  // const todoSetState = useSetRecoilState(todoState);
  const { post } = useAxios()
  const navigate = useNavigate()

  const signUpWithEmail = async (email: string, password: string) => {
    try {
      console.log('Rdaofhosadif')
      const auth = getAuth()

      await createUserWithEmailAndPassword(auth, email, password)
      const idToken = await getAuth().currentUser?.getIdToken(true)
      if (!auth.currentUser || !auth.currentUser.email)
        throw new Error('There is an issue with the user account')
      await post(
        '/user',
        {},
        {
          headers: { authorization: `Bearer: ${idToken}` }
        }
      )
      setState({ isSignedIn: true, idToken, userData: { email: auth.currentUser.email } })
    } catch (e) {
      console.log(e)
    }
  }

  const signInWithEmail = async (email: string, password: string) => {
    try {
      const auth = getAuth()

      await signInWithEmailAndPassword(auth, email, password)
      console.log(auth.currentUser)
      // This could cause issues but I dont think so, should always be an email on account
      if (!auth.currentUser || !auth.currentUser.email)
        throw new Error('There is an issue with the user account')
      const idToken = await getAuth().currentUser?.getIdToken(true)
      setState({ isSignedIn: true, idToken, userData: { email: auth.currentUser.email } })

      if (!idToken) throw new Error('Invalid IdToken for user')
      // REPLACE THIS
      // const res = await get<Todo[]>(`/todo`, {
      //   headers: { authorization: `Bearer: ${idToken}` },
      // });

      // todoSetState(res.data);
    } catch (e) {
      console.log(e)
    }
  }

  const signOutUser = async () => {
    try {
      const auth = getAuth()
      await signOut(auth)
      setState({ isSignedIn: false })
      setSBState(false)
      navigate('/login')
    } catch (e) {
      console.log(e)
    }
  }

  const refreshIdToken = async () => {
    try {
      const auth = getAuth()
      const idToken = await auth.currentUser?.getIdToken()
      if (!idToken) throw new Error('Failed to keep user logged in')
      setState(prev => ({ ...prev, idToken }))
      return idToken
    } catch (e) {
      console.log(e)
    }
  }

  return {
    authState: state,
    // checkIfSignedIn,
    signUpWithEmail,
    signInWithEmail,
    signOutUser,
    refreshIdToken
  }
}

export default useFirebase
