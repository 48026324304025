import dayjs from 'dayjs'

import { BackendEvent, Vendor, EventTypes } from 'Types'

const defaultValues: BackendEvent<Vendor, dayjs.Dayjs> = {
  urlName: '',
  sections: [
    { type: 'banner', image: '', alt: '' },
    {
      type: 'title',
      date: dayjs(),
      name: '',
      creators: [{ name: '', url: '' }],
      description: ''
    },
    {
      type: 'highlights',
      items: [{ highlight: 'test' }]
    },
    {
      type: 'text',
      variant: 'h5',
      items: [{ type: 'text', text: '', url: '' }]
    },
    {
      type: 'vendors',
      vendors: [{ image: '', handle: '', url: '', alt: '' }]
    },
    {
      type: 'timeLocation',
      time: {
        dates: [{ date: dayjs(), startTime: dayjs(), endTime: dayjs() }],
        hours: ''
      },
      location: {
        name: '',
        address: '',
        extraInfo: ''
      }
    },
    {
      type: 'map',
      lat: 0,
      lng: 0,
      markerText: ''
    },
    {
      type: 'zoomImage',
      image: '',
      alt: '',
      orientation: 'horizontal'
    },
    {
      type: 'eventTicket',
      cb: () => {},
      bg: '',
      leftContent: {
        header: '',
        sub: '',
        barcode: true
      },
      rightContent: {
        firstHeader: '',
        firstSub: '',
        secondHeader: '',
        secondSub: ''
      }
    }
  ],
  status: 'active',
  rsvp: 0,
  eventData: {
    urlPrefix: '',
    bg: { full: '', mobile: '', colour: '' },
    thumbnail: '',
    banner: { type: 'banner', image: '', alt: '' },
    title: {
      type: 'title',
      date: dayjs(),
      name: '',
      creators: [{ name: '', url: '' }],
      description: ''
    },
    version: 1.0,
  }
}

const mapTypestoTitle = (type: EventTypes) => {
  switch (type) {
    case 'banner':
      return 'Banner'
    case 'eventTicket':
      return 'Event Tickets'
    case 'highlights':
      return 'Highlights'
    case 'map':
      return 'Maps'
    case 'text':
      return 'Items'
    case 'timeLocation':
      return 'Time and Location'
    case 'title':
      return 'Title'
    case 'vendors':
      return 'Vendors'
    case 'zoomImage':
      return 'Zoom Images'
  }
}

// const testValues: BackendEvent<Vendor> = {
//   name: 'test Event',
//   sections: [
//     { type: 'banner', image: '', alt: 'test Event Banner' },
//     {
//       type: 'title',
//       date: new Date(),
//       title: 'Yo Diidy dango',
//       creators: [
//         { name: 'Diddy', url: 'some url' },
//         { name: 'Dango', url: 'some other url' }
//       ],
//       description: 'YEEEHAW DIDY AND DANGO'
//     },
//     {
//       type: 'highlights',
//       items: [{ highlight: 'Diddy' }, { highlight: 'Dango' }, { highlight: 'YEEHAW' }]
//     },
//     {
//       type: 'text',
//       variant: 'h5',
//       items: [{ type: 'text', text: 'Lets go Didy and Dango', url: 'this url' }]
//     },
//     {
//       type: 'vendors',
//       vendors: [
//         { image: '', handle: '@diddy', url: 'diddy.diddy', alt: 'diddys vendor' },
//         { image: '', handle: '@dango', url: 'dango.dango', alt: 'dangos vendor' }
//       ]
//     },
//     {
//       type: 'timeLocation',
//       time: {
//         date: new Date(),
//         hours: '12:00 - 7:00'
//       },
//       location: {
//         name: 'Diddys outback',
//         address: '123 Diddy lane',
//         extraInfo: 'Stay in Diddys lane'
//       }
//     },
//     {
//       type: 'map',
//       lat: 122,
//       lng: 69,
//       markerText: 'Diddys place'
//     },
//     {
//       type: 'zoomImage',
//       image: '',
//       alt: 'Diddys Image',
//       orientation: 'vertical'
//     },
//     {
//       type: 'eventTicket',
//       cb: () => {},
//       bg: '',
//       leftContent: {
//         header: 'DIDDY ',
//         sub: 'Diddysdaf',
//         barcode: true
//       },
//       rightContent: {
//         firstHeader: 'Dango',
//         firstSub: 'Leggo  ',
//         secondHeader: 'All the',
//         secondSub: 'Way'
//       }
//     }
//   ],
//   urlPrefix: '',
//   bg: { full: '', mobile: '', colour: '' },
//   isActive: false,
//   thumbnail: ''
// }

export { defaultValues, mapTypestoTitle }
