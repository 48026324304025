import * as React from 'react'
import { Grid, Typography, IconButton, Box } from '@mui/material'
import { Add, Remove } from '@mui/icons-material'

import BasicDropdown from 'Components/BasicDropdown'
import styles from './styles'
import { HeaderProps, SelectItems } from 'Types'
import ToolTip from 'Components/Tooltip'

// Component is Deprecated in favour of FormHeaderV2
const FormHeader: React.FC<HeaderProps> = ({
  title,
  variant,
  insert,
  remove,
  index,
  enableActions = true,
  defaultValue,
  defaultSections,
  toolTip
}) => {
  const sectionNames: SelectItems[] = defaultSections
    .reduce(
      (accum, section) => [...accum, { value: section.type }],
      [{ value: '' }]
      // Not sure how to get it to work with an empty array so I dont have to remove the first array value
    )
    .slice(1)

  const [state, setState] = React.useState<string>(defaultValue || sectionNames[0].value)

  const handleClick = () => {
    const section = defaultSections.find(section => section.type === state)
    if (section) return insert(index + 1, section)
  }

  return (
    <Grid item xs={12} sx={styles.header}>
      <Box sx={{ display: 'flex', alignItems: 'center'}}>
        <Typography variant={variant || 'h5'}>{title}</Typography>
        {toolTip ? <ToolTip text={toolTip} /> : undefined }
      </Box>

      <div hidden={!enableActions}>
        <BasicDropdown
          label='New Section'
          selectItems={sectionNames}
          setState={setState}
          defaultValue={defaultValue}
        />
        <IconButton onClick={handleClick}>
          <Add />
        </IconButton>
        <IconButton onClick={() => remove(index)}>
          <Remove />
        </IconButton>
      </div>
    </Grid>
  )
}

export default FormHeader
