import * as React from 'react'
import { Grid, Button } from '@mui/material'
import qrcode from 'qrcode'
import { useForm } from 'react-hook-form'

import { TextField, ColorPicker, Dropdown } from 'Components/Form'
import { SelectItems } from 'Types'
import { useAxios } from 'Hooks'

const styles = {
  container: {
    marginTop: '2em',
    justifyContent: 'center'
  }
}
type Props = {}

type QrCodeProps = {
  url: string
  eventName: string
  dark: string
  light: string
}

const QrCodeGen: React.FC<Props> = () => {
  const [dropDown, setDropDown] = React.useState<SelectItems[]>()
  const { get } = useAxios()
  const { control, getValues } = useForm<QrCodeProps>({
    defaultValues: { url: 'www.google.com', eventName: '', dark: '#000', light: '#FFF' }
  })

  React.useEffect(() => {
    if (!dropDown) {
      get<{ urlName: string }[]>('events/names').then(({ data }) => {
        console.log({ data })
        setDropDown([{ name: 'None', value: '' }, ...data.map(item => ({ value: item.urlName }))])
      })
    }
  }, [dropDown, get])

  const createQrCode = () => {
    const values = getValues()
    const canvas = document.getElementById('canvas')

    qrcode.toCanvas(
      canvas,
      values.eventName ? `https://www.jkcharms.ca/events/${values.eventName}` : values.url,
      {
        color: {
          dark: values.dark,
          light: values.light
        },
        scale: 10
      }
    )
  }

  return (
    <Grid
      justifyContent='flex-start'
      alignItems='flex-end'
      container
      spacing={2}
      sx={styles.container}
    >
      <TextField
        fieldName='url'
        label='Link Url'
        control={control}
        sx={{ width: '100%' }}
        disableSpacer
        gridProps={{ xs: 12, md: 8 }}
      />
      {dropDown && (
        <Dropdown
          control={control}
          fieldName={'eventName'}
          label={'or Choose an Event'}
          sx={{ width: '100%' }}
          selectItems={dropDown}
          defaultValue={dropDown[0]}
          disableSpacer
          gridProps={{ xs: 12, md: 4 }}
        />
      )}
      <ColorPicker
        fieldName='dark'
        control={control}
        label='Pick Dark Color'
        disableSpacer
        gridProps={{ xs: 12, md: 3 }}
      />
      <ColorPicker
        fieldName='light'
        control={control}
        label='Pick Light Color'
        disableSpacer
        gridProps={{ xs: 12, md: 3 }}
      />
      <Grid item xs={12} md={3}>
        <Button type='submit' variant='contained' onClick={createQrCode}>
          Create Qr Code
        </Button>
      </Grid>
      <Grid item xs={12}>
        <canvas id='canvas'></canvas>
      </Grid>
    </Grid>
  )
}

export default QrCodeGen
