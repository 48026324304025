import * as React from 'react'

import { IconButton } from '@mui/material'
import { UploadFile, Delete } from '@mui/icons-material'

import { UploadProps, Files } from 'Types'
import {
  UploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  InputLabel
} from './styles'

const MAXFILESIZE = 10000000
const UploadComponent: React.FC<UploadProps> = ({
  label,
  maxFileSize = MAXFILESIZE,
  updateFilesCb,
  multiple
}) => {
  const fileInputField = React.useRef<HTMLInputElement>(null)
  const fileInputButton = React.useRef<HTMLButtonElement>(null)
  const fileInputContainer = React.useRef<HTMLDivElement>(null)
  const [files, setFiles] = React.useState<Files>({})

  const convertBytesToKB = (bytes: number) => Math.round(bytes / 1000)
  const uploadBtnClick = () => {
    if (!fileInputField.current) return
    fileInputField.current.click()
  }

  const addNewFiles = (newFiles: FileList) => {
    const filesArray: File[] = []
    const newFilesArray = Array.from(newFiles)
    for (let file of newFilesArray) {
      if (file.size <= maxFileSize) {
        if (!multiple) {
          return [file]
        }
        filesArray.push(file)
      }
    }
    return filesArray
  }

  const handleNewFileUpload = (e: any) => {
    const { files: newFiles } = e.target
    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles)
      setFiles(prev => {
        const newFilesObject: Files = { ...prev }
        updatedFiles.forEach(file => {
          newFilesObject[file.name] = file
        })
        return newFilesObject
      })
    }
  }

  const removeFile = (fileName: string) => {
    setFiles(prev => {
      const newFiles = { ...prev }
      delete newFiles[fileName]
      return newFiles
    })
  }

  const dragEnter = (e: any) => {
    if (!fileInputButton.current || !fileInputContainer.current) return
    fileInputButton.current.style.visibility = 'hidden'
    // Make these colours nicer
    fileInputContainer.current.style.border = '2px dotted darkgreen'
    fileInputContainer.current.style.background = 'lightgreen'
  }

  const dragExit = (e: any) => {
    if (!fileInputButton.current || !fileInputContainer.current) return
    fileInputButton.current.style.visibility = 'visible'
    fileInputContainer.current.style.border = '2px dotted lightgrey'
    fileInputContainer.current.style.background = 'white'
  }

  React.useEffect(() => {
    // NEED TO FIX THIS FILE SO THAT IT BRINGS IN AND EXPORTS STRINGS INSTEAD OF FILES
    // const filesArray = Object.keys(files).map(key => files[key])
    // updateFilesCb(filesArray)
  }, [files, updateFilesCb])

  return (
    <>
      <UploadContainer
        onDragOver={dragEnter}
        onDrop={dragExit}
        onDragLeave={dragExit}
        ref={fileInputContainer}
      >
        <InputLabel>{label}</InputLabel>
        <DragDropText>Drag and drop your files anywhere or</DragDropText>
        <UploadFileBtn type='button' onClick={uploadBtnClick} ref={fileInputButton}>
          <UploadFile />
          <span> Upload {multiple ? 'files' : 'a file'}</span>
        </UploadFileBtn>
        <FormField
          type='file'
          ref={fileInputField}
          title=''
          // value=''
          accept='image/png, image/gif, image/jpeg'
          multiple
          onChange={handleNewFileUpload}
        />
      </UploadContainer>
      <FilePreviewContainer>
        <span>To Upload</span>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            const file = files[fileName]
            const isImageFile = file.type.split('/')[0] === 'image'

            return (
              <PreviewContainer key={fileName}>
                <div style={{ height: 'inherit' }}>
                  {isImageFile && (
                    <ImagePreview src={URL.createObjectURL(file)} alt={`file preview ${index}`} />
                  )}
                  <FileMetaData>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <IconButton onClick={() => removeFile(fileName)}>
                        <Delete />
                      </IconButton>
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            )
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  )
}

export default UploadComponent
