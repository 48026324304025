import * as React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'

import { useAuthState } from 'Hooks'

type Props = {}

const RequireAuth: React.FC<Props> = ({ children }) => {
  const location = useLocation()

  const {
    authState: { isSignedIn },
    isChecking
  } = useAuthState()

  // React.useEffect(() => console.log({ isChecking }), [isChecking])
  return (
    <>
      {isChecking ? (
        <CircularProgress />
      ) : isSignedIn ? (
        children
      ) : (
        <Navigate to='/login' replace state={{ path: location.pathname }} />
      )}
    </>
  )
}

export default RequireAuth
