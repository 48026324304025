import * as React from 'react'
import { useForm, SubmitHandler, useFieldArray, FormProvider } from 'react-hook-form'
import { Button, Grid, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent
} from '@dnd-kit/core'

import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'

import { TextField } from 'Components/Form'
import { Background, HeaderV2 } from 'Components/EventForms'
import SnackAlert from 'Components/SnackAlert'
import determineComponent from './forms'
import { defaultValues as presetValues, mapTypestoTitle } from './helpers'
import { BackendEvent, EventTimeLocation, EventTitle, EventVendor, Vendor } from 'Types'
import { useAxios, useAlertState, useLocalStorage } from 'Hooks'
import ToolTip from 'Components/Tooltip'

type Props = {}
type EventNames = { name: string }

const styles = {
  container: {
    marginTop: '2em',
    '@media (min-width: 2000px)': {
      width: '50%'
    }
  },
  form: {
    display: 'flex',
    justifyContent: 'center'
  },
  item: {
    width: '100%'
  }
}

const NewEvent: React.FC<Props> = () => {
  const { post, put, get, patch } = useAxios()
  // const { refreshIdToken } = useFirebase()
  const { setAlert } = useAlertState()
  const { setEventVersion } = useLocalStorage()
  const [eventNames, setEventNames] = React.useState<string[]>([])
  const [defaultValues, setDefaultValues] =
    React.useState<BackendEvent<Vendor, Dayjs>>(presetValues)
  const [update, setUpdate] = React.useState(false)
  const [vendorList, setVendorList] = React.useState<Vendor[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const firstRender = React.useRef(true)
  const [activeId, setActiveId] = React.useState<{ id: string; title: string }>()

  const location = useLocation()

  dayjs.extend(utc)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const mapDates = React.useCallback(
    (event: BackendEvent<Vendor, string>): BackendEvent<Vendor, Dayjs> => {
      return {
        ...event,
        eventData: {
          ...event.eventData,
          title: {
            ...event.eventData.title,
            date: dayjs(event.eventData.title.date)
          }
        },
        sections: event.sections.map(section => {
          if (section.type === 'title') {
            return {
              ...section,
              date: dayjs(section.date)
            }
          } else if (section.type === 'timeLocation') {
            return {
              ...section,
              time: {
                dates: section.time.dates.map(({ date, startTime, endTime }) => ({
                  date: dayjs(date),
                  startTime: dayjs(startTime),
                  endTime: dayjs(endTime)
                })),
                hours: section.time.hours,
                extraInfo: section.time.extraInfo
              }
            }
          } else return section
        })
      }
    },
    []
  )

  React.useEffect(() => {
    const eventName = location.pathname.split('edit/')[1]

    const getEvent = async () => {
      try {
        const event = await get<BackendEvent<Vendor, string>>(`events/${eventName}`)
        const { urlName, sections, eventData } = event.data
        console.log('backend data', JSON.parse(JSON.stringify(event.data)))
        console.log('rsvp', event.data.rsvp)
        if (!urlName || !sections || !eventData) throw new Error('Issue with loading Data')

        // await mapImages(event.data)
        setDefaultValues(mapDates(event.data))
        setUpdate(true)
      } catch (e) {
        console.log(e)
      }
    }

    const getVendors = async () => {
      try {
        const { data: vendors } = await get<Vendor[]>('admin/events/vendors')
        console.log({ vendors })
        if (!vendors || !vendors.length) throw new Error('Failed to Load vendor Data')
        setVendorList(vendors)
      } catch (e) {
        console.log(e)
      }
    }

    const getEventNames = async () => {
      try {
        const { data } = await get<EventNames[]>('events/names')
        console.log({ data })
        if (!data || !data.length) throw new Error('Failed to Load event names Data')
        setEventNames(data.map(({ name }) => name))
      } catch (e) {
        console.log(e)
      }
    }

    const getData = () => {
      firstRender.current = false
      console.log({ eventName })
      if (eventName) getEvent()
      if (!vendorList.length) getVendors()
      if (!eventNames.length) getEventNames()
    }

    if (firstRender.current) getData()
  }, [location, get, vendorList.length, eventNames.length, mapDates])

  const methods = useForm({ defaultValues })
  const { handleSubmit, control, reset } = methods

  // Look up way to have the useForm async pull defaultValues
  React.useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  const { fields, remove, insert, move } = useFieldArray({ control, name: 'sections' })

  const handleDragStart = (event: DragStartEvent) => {
    console.log({ event })

    const fieldEvent = fields.find(field => field.id === event.active.id)
    if (!fieldEvent) return null
    setActiveId({ id: event.active.id as string, title: mapTypestoTitle(fieldEvent.type) })
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const active = event.active.data.current?.sortable.index
    const over = event.over?.data.current?.sortable.index

    if (active === undefined || over === undefined) return undefined

    if (active !== over) {
      move(active, over)
    }

    setActiveId(undefined)
  }

  const onSubmit: SubmitHandler<BackendEvent<Vendor, Dayjs>> = async event => {
    const urlName = event.urlName
    const getSignedUrl = (folderName: string, itemName: string) =>
      post<string>('admin/getSignedURL', {
        bucketType: 'eventBucket',
        folderName,
        itemName
      })

    const getImage = async (imageUrl: string, imageName: string) => {
      try {
        console.log('getting ', imageUrl)
        const { data } = await get(imageUrl, { responseType: 'blob', baseURL: '' })
        const blob = data as Blob
        const file = new File([blob], imageName, { type: blob.type })
        return file
      } catch (e) {
        console.log(e)
      }
    }

    const uploadImage = async (
      imageUrl: string | undefined,
      folderName: string,
      imageName: string
    ) => {
      if (!imageUrl) return ''
      if (imageUrl.includes('blob')) {
        const image = await getImage(imageUrl, imageName)
        if (!image) return ''

        const { data: url } = await getSignedUrl(folderName, imageName)
        event.eventData.urlPrefix = url.split(folderName)[0]
        console.log('uploading image')
        await put(url, image, {
          headers: { 'Content-Type': image.type }
        })
        return event.eventData.urlPrefix + folderName + '/' + imageName
      } else return imageUrl
    }

    const uploadVendor = async (vendor: Vendor, update: boolean) => {
      try {
        const { data } = update
          ? await put<Vendor>('admin/events/vendors', { vendor })
          : await post<Vendor>('admin/events/vendors', { vendor })

        return data
      } catch (e) {
        console.log({ e })
      }
    }

    const handleVendor = async (newVendor: Vendor) => {
      if (!newVendor.handle) return
      let isNew = true
      let isUpdated = false
      for (let vendor of vendorList) {
        // Check if the handle exists, if it doesnt then it will be considered a completely new vendor
        if (vendor.handle === newVendor.handle) {
          isNew = false
          if (vendor.url !== newVendor.url || vendor.alt !== newVendor.alt) isUpdated = true
          if (vendor.image !== newVendor.image) isUpdated = true
        }
      }

      try {
        if (isUpdated) {
          console.log('should update', { newVendor })
          newVendor.image = await uploadImage(newVendor.image, 'shopicons', newVendor.handle)
          console.log('after', { newVendor })
          const updated = await uploadVendor(newVendor, true)
          console.log({ updated })
        }

        if (isNew) {
          console.log('should be completely new Vendor', { newVendor })
          newVendor.image = await uploadImage(newVendor.image, 'shopicons', newVendor.handle)
          console.log('after', { newVendor })
          const created = await uploadVendor(newVendor, false)
          console.log({ created })
          if (created) {
            vendorList.push(created)
          }
        }
        return newVendor
      } catch (e) {
        console.log({ e })
      }
    }

    const handleSections = async ({ sections, ...rest }: BackendEvent<Vendor, Dayjs>) => {
      console.log('setting rest', JSON.parse(JSON.stringify(rest)))
      const backendEvent: BackendEvent<string, string> = {
        ...rest,
        eventData: {
          ...rest.eventData,
          title: {
            ...rest.eventData.title,
            date: rest.eventData.title.date.format()
          }
        },
        sections: []
      }
      let zoomSections = 0

      for await (let section of sections) {
        if (section.type === 'banner') {
          section.image = await uploadImage(section.image, urlName, 'banner')
          backendEvent.sections.push(section)
        } else if (section.type === 'title') {
          const newSection: EventTitle<string> = {
            ...section,
            date: section.date.format()
          }
          // section.date = section.date.toDate().toDateString()
          backendEvent.sections.push(newSection)
        } else if (section.type === 'text' || section.type === 'highlights') {
          backendEvent.sections.push(section)
        } else if (section.type === 'vendors') {
          const newSection: EventVendor<string> = {
            type: 'vendors',
            vendors: []
          }
          for await (let vendor of section.vendors) {
            await handleVendor(vendor)
            newSection.vendors.push(vendor.handle)
            // vendor.image = await uploadImage(vendor.image, 'shopicons', vendor.handle)
          }
          backendEvent.sections.push(newSection)
        } else if (section.type === 'timeLocation') {
          console.log({ section })
          const newSection: EventTimeLocation<string> = {
            ...section,
            time: {
              dates: section.time.dates.map(({ date, endTime, startTime }) => ({
                date: date.format(),
                startTime: startTime.set('D', date.get('date')).format(),
                endTime: endTime.set('D', date.get('date')).format()
              })),
              hours: section.time.hours
            }
          }
          backendEvent.sections.push(newSection)
        } else if (section.type === 'map') {
          if (typeof section.lat !== 'number') section.lat = Number(section.lat)
          if (typeof section.lng !== 'number') section.lng = Number(section.lng)
          backendEvent.sections.push(section)
        } else if (section.type === 'zoomImage') {
          const imageName = `zoomImage-${zoomSections}`
          section.image = await uploadImage(section.image, urlName, imageName)
          zoomSections++
          console.log({ zoomSections })
          backendEvent.sections.push(section)
        } else if (section.type === 'eventTicket') {
          section.bg = await uploadImage(section.bg, urlName, 'ticketbg')
          backendEvent.sections.push(section)
        }
      }

      return backendEvent
    }

    try {
      setLoading(true)
      const backendEvent = await handleSections(event)
      backendEvent.eventData.bg.full = await uploadImage(event.eventData.bg.full, urlName, 'bgFull')
      backendEvent.eventData.bg.mobile = await uploadImage(
        event.eventData.bg.mobile,
        urlName,
        'bgMobile'
      )
      backendEvent.eventData.thumbnail = await uploadImage(
        event.eventData.thumbnail,
        urlName,
        'thumbnail'
      )

      backendEvent.sections.forEach(section => {
        if (section.type === 'banner') backendEvent.eventData.banner = section
        else if (section.type === 'title') backendEvent.eventData.title = section
      })

      // // Make sure its forced set
      backendEvent.eventData.urlPrefix =
        event.eventData.urlPrefix || 'https://jkcharmsevents.s3.us-west-2.amazonaws.com/'

      backendEvent.status = backendEvent.status || 'active'

      console.log({ event })
      console.log({ backendEvent })

      // await refreshIdToken()

      if (update) {
        backendEvent.eventData.version += 0.1
        setEventVersion(backendEvent.urlName, backendEvent.eventData.version)
        console.log('patching', backendEvent)
        patch('admin/events', { event: backendEvent })
      } else {
        backendEvent.rsvp = 0
        console.log('posting', backendEvent)
        await post('admin/events', {
          event: backendEvent
        })
      }
      setLoading(false)
      setAlert({
        msg: update
          ? `${backendEvent.eventData.title.name} has been updated!`
          : `${backendEvent.eventData.title.name} has been created!`,
        severity: 'success'
      })
      // console.log({ result })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setAlert({ msg: 'Failed to create Event', severity: 'error' })
      console.log(e)
    }
  }

  return (
    <FormProvider {...methods}>
      <form style={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={2}
          sx={styles.container}
          alignContent={'flex-start'}
          justifyContent={'flex-start'}
        >
          <Grid item xs={12}>
            <Typography variant='h4'>Create a new Event Page</Typography>
            <Typography variant='h5'>
              This is a fully customizable create event page form, you can add or remove sections of
              the form as you need. If you want to remove a section just click the - on the top
              header, if you want to add then select the section you want to add from the dropdown
              and click the plus.
            </Typography>
          </Grid>
          <TextField
            control={control}
            fieldName={'urlName'}
            label={'Event Url name'}
            gridProps={{ xs: 12, md: 6 }}
            sx={{ width: '100%' }}
            controllerProps={{
              rules: {
                required: 'Event Name is a required Field',
                validate: value => {
                  if (eventNames.includes(value)) {
                    if (update) return true
                    else return 'This event name is already being used'
                  }
                  return true
                }
              }
            }}
            onChangeFilter={value =>
              value
                .replace(/([a-z])([A-Z])/g, '$1-$2')
                .replace(/[\s_]+/g, '-')
                .toLowerCase()
            }
          />
          <ToolTip text='This is the what builds the url for the event, spaces get turned into - and has to be unique from any other event name' />
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <SortableContext items={fields} strategy={verticalListSortingStrategy}>
              {fields.map(({ type, id }, index) =>
                determineComponent({
                  control,
                  index,
                  id,
                  type,
                  insert,
                  remove,
                  vendorList,
                  defaultSections: presetValues.sections,
                  activeId: activeId?.id
                })
              )}
              <DragOverlay>
                <HeaderV2 index={0} title={activeId?.title || ''} remove={remove} />
              </DragOverlay>
            </SortableContext>
          </DndContext>

          <Background
            id='background-id'
            control={control}
            insert={insert}
            index={0}
            remove={remove}
            defaultSections={presetValues.sections}
          />

          <Grid item>
            <Button variant='contained' type='submit' disabled={loading}>
              Submit Form
            </Button>
          </Grid>
        </Grid>
        <SnackAlert />
      </form>
    </FormProvider>
  )
}

export default NewEvent
