import * as React from 'react'
import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Typography
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import { cashEventState, vendoringEventState } from 'State'
import CashCheckoutDialog from 'Components/CashCheckoutDialog'
import ProductDialog from 'Components/ProductDialog'
import { Item, Transaction, CashEvent, VendoringEvent, VendorItem } from 'Types/Products'
import { useAlertState, useAxios } from 'Hooks'
import ConfirmDialog from 'Components/ConfirmDialog'
import SnackAlert from 'Components/SnackAlert'
import { ConfirmDialogProps } from 'Types'

type Props = {}

const styles = {
  image: {
    height: '3em',
    width: '3em',
    '@media (minWidth: 800px)': {
      height: '5em',
      width: '5em'
    }
  },
  link: {
    textDecoration: 'none',
    color: 'lightblue'
  }
}

const Drawer: React.FC<Props> = () => {
  const [cashEvent, setCashEvent] = useRecoilState(cashEventState)
  const [vendorEvent, setVendorEvent] = useRecoilState(vendoringEventState)
  const [open, setOpen] = React.useState(false)
  const [openCart, setOpenCart] = React.useState(false)
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [selItem, setSelItem] = React.useState<VendorItem>()
  const [transaction, setTransaction] = React.useState<Transaction>({
    itemNames: [],
    items: [],
    tender: 0,
    transactionTotal: ''
  })
  const [editItem, setEditItem] = React.useState<{ item: Item; index: number }>()
  const [dialog, setDialog] = React.useState<Partial<ConfirmDialogProps>>({})
  const [loading, setLoading] = React.useState(false)
  const { setAlert } = useAlertState()

  const navigate = useNavigate()
  const { get, put, remove, patch } = useAxios()
  const location = useLocation()
  const eventId = location.pathname.split('drawer/')[1]

  React.useEffect(() => {
    const getEventData = async () => {
      const {
        data: { cashEvent, vendorEvent }
      } = await get<{ cashEvent: CashEvent; vendorEvent: VendoringEvent }>(
        `/admin/inventory/event/${eventId}`
      )
      setCashEvent(cashEvent)
      setVendorEvent(vendorEvent)
    }

    if ((!cashEvent && !vendorEvent) || eventId !== cashEvent?._id) {
      getEventData()
    }
  }, [get, cashEvent, setCashEvent, vendorEvent, setVendorEvent, eventId])

  React.useEffect(() => {
    if (editItem?.item) {
      console.log({ editItem })
      setSelItem(() => {
        return vendorEvent?.items.find(item => item.id === editItem.item.itemId)
      })
      setOpen(true)
    }
  }, [editItem, setOpen, setSelItem, vendorEvent])

  const getItemCount = () => transaction.items.reduce((acc, curr) => acc + +curr.quantity, 0)
  const getTotalAmount = () =>
    transaction.items.reduce((acc, curr) => {
      const currPrice = +curr.alternativePrice || +curr.variationPrice / 100
      const itemTotal = currPrice * +curr.quantity

      return acc + itemTotal
    }, 0)

  const removeCashEvent = async () => {
    try {
      setLoading(true)
      await remove(`/admin/inventory/cashEvents/${eventId}`)
      setAlert({ msg: `Successfully Deleted Cash Event!`, severity: 'success' })
      navigate('/cash')
      setOpenConfirm(false)
    } catch (e) {
      console.log(e)
      setAlert({ msg: `Failed to Delete Cash Event!`, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const updateVendorEvent = async () => {
    try {
      setLoading(true)
      const { data } = await patch<VendoringEvent>(`/admin/inventory/event/${vendorEvent?.id}`, {})
      setVendorEvent(data)
      setAlert({ msg: 'Successfully Updated Vendor Event!', severity: 'success' })
      setOpenConfirm(false)
    } catch (e) {
      console.log(e)
      setAlert({ msg: 'Failed to update Vendor Event', severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  const updateVendorItem = async (
    item: VendorItem,
    setItem: React.Dispatch<React.SetStateAction<VendorItem | undefined>>
  ) => {
    setOpenConfirm(true)
    setDialog({
      description: 'Would you like to update this Listing to match square?',
      confirmButtonText: 'Update Listing',
      confirmColour: 'primary',
      onConfirm: async () => {
        try {
          setLoading(true)
          const { data } = await patch<VendoringEvent>(
            `/admin/inventory/event/${vendorEvent?.id}/${item.id}/${item._id}`,
            {}
          )

          console.log({ data })
          setItem(data.items.find(newItem => newItem.id === item.id))
          setVendorEvent(data)
          setAlert({ msg: 'Successfully updated Vendor Item!', severity: 'success' })
          setOpenConfirm(false)
        } catch (e) {
          console.log(e)
          setAlert({ msg: 'Failed to update Vendor Item.', severity: 'error' })
        } finally {
          setLoading(false)
        }
      }
    })
  }

  const determineLabel = (item: VendorItem) => {
    if (item.variations.length > 1) return 'Varies'
    else if (item.variations[0].variationPricingType === 'VARIABLE_PRICING') return 'Variable'
    else if (item.variations[0].variationPrice)
      return `$${+item.variations[0].variationPrice.amount / 100}`
    else return 'Issue Reading Price'
  }

  return (
    <Grid container>
      {vendorEvent && cashEvent && (
        <>
          <Grid
            item
            xs={12}
            mt={'2em'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography variant='h5'>{vendorEvent.name}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            mt={'1em'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Link to={`/cash/drawer/transactions/${cashEvent._id}`} style={styles.link}>
              <Typography variant='h5'>
                Total Transactions: {cashEvent.transactions.length}
              </Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={6} mt={'1em'}>
            <Button
              variant='contained'
              onClick={() => {
                setDialog({
                  description:
                    'Warning, Updating the vendor event will also update all other Cash Events made from this vendor event. All previous transactions will not be affected',
                  onConfirm: updateVendorEvent,
                  confirmButtonText: 'Update Vendor Event',
                  confirmColour: 'primary'
                })
                setOpenConfirm(true)
              }}
            >
              Update Vendor Event
            </Button>
          </Grid>
          <Grid item xs={12} display={'flex'} flexDirection={'column'} mt={'2em'}>
            <Typography variant='h5'>Products:</Typography>
            <List dense sx={{ width: '100%' }}>
              {vendorEvent.items.length &&
                vendorEvent.items
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(item => (
                    <div key={item._id}>
                      <ListItem sx={{ pl: 0 }}>
                        <ListItemButton
                          sx={{ pl: 0 }}
                          onClick={() => {
                            setOpen(true)
                            setSelItem(item)
                          }}
                        >
                          <ListItemAvatar sx={{ mr: '1em' }}>
                            <Avatar
                              variant='square'
                              alt={`Image for ${item.name}`}
                              src={item.images[0]}
                              sx={styles.image}
                            />
                          </ListItemAvatar>
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography variant='body1'>{item.name}</Typography>
                            <Typography variant='body1'>{determineLabel(item)}</Typography>
                          </div>
                        </ListItemButton>
                      </ListItem>
                      <Divider variant='inset' component={'li'} />
                    </div>
                  ))}
            </List>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            setDialog({
              description: 'Are you sure you want to delete this Cash Event.',
              onConfirm: removeCashEvent
            })
            setOpenConfirm(true)
          }}
        >
          Delete Cash Event.
        </Button>
      </Grid>
      <CashCheckoutDialog
        open={openCart}
        setOpen={setOpenCart}
        loading={loading}
        onConfirm={async (tender, cb) => {
          try {
            setLoading(true)
            const newTransaction: Transaction = {
              transactionTotal: getTotalAmount().toString(),
              itemNames: transaction.items.map(item => item.itemName),
              items: transaction.items,
              tender
            }

            const { data } = await put<CashEvent>(
              `/admin/inventory/cashEvents/transactions/${cashEvent?._id}`,
              {
                transaction: newTransaction
              }
            )
            console.log('cashEvent', data)
            setOpenCart(false)
            setTransaction({ itemNames: [], items: [], tender: 0, transactionTotal: '' })
            setCashEvent(data)
            cb()
            setAlert({ msg: `Transaction Saved!`, severity: 'success' })
          } catch (e) {
            console.log(e)
            setAlert({ msg: `Failed to save Transaction!`, severity: 'error' })
          } finally {
            setLoading(false)
          }
        }}
        setTransaction={setTransaction}
        transaction={transaction}
        setEditItem={setEditItem}
      />
      <ProductDialog
        open={open}
        setOpen={setOpen}
        loading={loading}
        item={selItem}
        onConfirm={() => {}}
        setTransaction={setTransaction}
        editItem={editItem}
        setEditItem={setEditItem}
        updateCb={updateVendorItem}
      />
      <ConfirmDialog
        open={openConfirm}
        setOpen={setOpenConfirm}
        loading={loading}
        {...dialog}
        onConfirm={() => dialog.onConfirm && dialog.onConfirm()}
      />
      <div
        style={{
          width: '100%',
          height: '2em',
          backgroundColor: 'lightgreen',
          position: 'fixed',
          left: '0',
          bottom: '0',
          padding: '20px',
          cursor: 'pointer'
        }}
        onClick={() => setOpenCart(true)}
      >
        <Typography variant='h5'>{`${getItemCount()} Items. $${getTotalAmount()} Total.`}</Typography>
      </div>
      <SnackAlert />
    </Grid>
  )
}

export default Drawer
