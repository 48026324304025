import * as React from 'react'

import { Grid } from '@mui/material'
// import { useParams } from 'react-router-dom'

import { useAxios, useProductState } from 'Hooks'
import ImageCarousel from 'Components/ImageCarousel'
import { mockImages } from 'Views/mockData'
import { Product } from 'Types'

// const useStyles = makeStyles((theme: any) => ({}))

type Props = {}

const ProductItem: React.FC<Props> = () => {
  const { productList, addProd } = useProductState()
  // const [products, setProducts] = React.useState<Product[]>()
  const { get } = useAxios()

  const getProds = React.useCallback(async () => {
    try {
      const { data } = await get<Product[]>('products/home')
      addProd(data)
    } catch (err) {
      console.log(err)
    }
  }, [get, addProd])

  React.useEffect(() => {
    if (!productList.length) getProds()
  }, [productList.length, getProds])

  React.useEffect(() => console.log({ productList }), [productList])
  return (
    <Grid item container>
      {/* <Typography variant='h6'>This is product: {productId}</Typography> */}
      <Grid item xs={5}>
        <ImageCarousel images={mockImages} />
      </Grid>
    </Grid>
  )
}

export default ProductItem
