import * as React from 'react'
import { Typography, Grid } from '@mui/material'

import InlineButton from 'Components/InlineButton'
import { EventText } from 'Types'

const Text: React.FC<EventText> = ({ items, variant }) => {
  return (
    <Grid item xs={11} md={8}>
      {/* @ts-ignore */}
      <Typography variant={variant}>
        {items.map(item =>
          item.type === 'text' ? (
            item.text
          ) : (
            <InlineButton
              key={item.text}
              label={item.text}
              url={item.url || ''}
              color={item.buttonColor}
            />
          )
        )}
      </Typography>
    </Grid>
  )
}

export default Text
