import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getAuth } from 'firebase/auth'
import { useRecoilState } from 'recoil'

import { authState as AuthState } from 'State'

type AxiosGet = <T>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>
type AxiosPost = <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
) => Promise<AxiosResponse<T>>

type AxiosPut = <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
) => Promise<AxiosResponse<T>>

type AxiosPatch = <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
) => Promise<AxiosResponse<T>>

type AxiosDelete = <T>(url: string, config?: AxiosRequestConfig) => Promise<AxiosResponse<T>>

const useAxios = () => {
  const [authState, setAuthState] = useRecoilState(AuthState)
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API

  const determineAuth = (url: string, updatedToken?: string) => {
    if (!url.includes('https'))
      return {
        headers: {
          authorization: `Bearer ${updatedToken || authState.idToken || ' '}`
        }
      }
    else return {}
  }

  const checkUpdateToken = async () => {
    const newIdToken = await getAuth().currentUser?.getIdToken()
    const updateToken = newIdToken === authState.idToken ? undefined : newIdToken
    if (updateToken) setAuthState(prev => ({ ...prev, idToken: updateToken }))
    return updateToken
  }

  const get: AxiosGet = async (url, config?) => {
    const updateToken = await checkUpdateToken()

    return axios.get(url, {
      ...determineAuth(url, updateToken),
      ...config
    })
  }

  const post: AxiosPost = async (url, data, config?) => {
    const updateToken = await checkUpdateToken()

    return axios.post(url, data, {
      ...determineAuth(url, updateToken),
      ...config
    })
  }

  const put: AxiosPut = async (url, data, config?) => {
    const updateToken = await checkUpdateToken()

    return axios.put(url, data, {
      ...determineAuth(url, updateToken),
      ...config
    })
  }

  const patch: AxiosPatch = async (url, data, config?) => {
    const updateToken = await checkUpdateToken()

    return axios.patch(url, data, {
      ...determineAuth(url, updateToken),
      ...config
    })
  }

  const remove: AxiosDelete = async (url, config?) => {
    const updateToken = await checkUpdateToken()

    return axios.delete(url, {
      ...determineAuth(url, updateToken),
      ...config
    })
  }

  return { get, post, put, patch, remove }
}

export default useAxios
