import * as React from 'react'
import { Grid, Button, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'

import { useAxios, useProductState } from 'Hooks'
import { Product } from 'Types'
import ProductCard from 'Components/ProductCard'

type Props = {}

const styles = {
  container: {
    marginTop: '2em',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  innerContainer: {
    flexDirection: 'row'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  }
}

const ProductPage: React.FC<Props> = () => {
  const { get } = useAxios()
  const { addProd, productList } = useProductState()

  const getProds = React.useCallback(async () => {
    try {
      const { data } = await get<Product[]>('products')
      addProd(data)
    } catch (err) {
      console.log(err)
    }
  }, [get, addProd])

  React.useEffect(() => {
    if (!productList.length) getProds()
  }, [productList.length, getProds])

  return (
    <Grid container spacing={2} sx={styles.container}>
      <Grid item>
        <Button variant='contained'>
          <Link to='./new' style={styles.link}>
            Add New Product
          </Link>
        </Button>
      </Grid>
      <Grid item container sx={styles.innerContainer}>
        {productList.length ? (
          productList.map(prod => <ProductCard key={'Product' + prod.productId} prod={prod} />)
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  )
}

export default ProductPage
