import * as React from 'react'

import { Controller } from 'react-hook-form'

import { MultipleValues as MultipleValuesAutocomplete } from 'Components/Autocomplete'
import gridWrapper from 'Utils/GridWrapper'
import { MultipleValuesFormProps } from 'Types'

const FormMultipleValues: React.FC<MultipleValuesFormProps> = ({
  fieldName,
  label,
  placeholder,
  control,
  controllerProps,
  options
}) => {
  return (
    <Controller
      name={fieldName}
      {...controllerProps}
      render={({ field }) => (
        <MultipleValuesAutocomplete
          label={label}
          placeholder={placeholder}
          options={options}
          onChange={field.onChange}
        />
      )}
      control={control}
    />
  )
}

export const MultipleValues = ({
  disableGrid,
  disableSpacer,
  gridProps,
  spacerProps,
  ...props
}: MultipleValuesFormProps) => {
  return gridWrapper<MultipleValuesFormProps>({
    disableGrid,
    disableSpacer,
    Component: FormMultipleValues,
    props,
    gridProps,
    spacerProps,
    key: props.fieldName
  })
}

export default MultipleValues