import {
  Banner,
  Title,
  Highlights,
  Items,
  Vendors,
  TimeLoc,
  Map,
  ZoomImage,
  EventTicket
} from 'Components/EventForms'
import { EventFormProps, Vendor } from 'Types'

type CompProps = {
  type: string
  id: string
  vendorList: Vendor[]
} & EventFormProps

const determineComponent = ({ vendorList, ...rest }: CompProps) => {
  if (rest.type === 'banner') return <Banner key={rest.id} {...rest} />
  else if (rest.type === 'title') return <Title key={rest.id} {...rest} />
  else if (rest.type === 'highlights') return <Highlights key={rest.id} {...rest} />
  else if (rest.type === 'text') return <Items key={rest.id} {...rest} />
  else if (rest.type === 'vendors') return <Vendors key={rest.id} vendors={vendorList} {...rest} />
  else if (rest.type === 'timeLocation') return <TimeLoc key={rest.id} {...rest} />
  else if (rest.type === 'map') return <Map key={rest.id} {...rest} />
  else if (rest.type === 'zoomImage') return <ZoomImage key={rest.id} {...rest} />
  else if (rest.type === 'eventTicket') return <EventTicket key={rest.id} {...rest} />
}

export default determineComponent
