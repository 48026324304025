import * as React from 'react'

import { useForm, SubmitHandler } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'

import { TextField } from 'Components/Form'
import { useFirebase } from 'Hooks'
import { SignInFields } from 'Types'
import { Grid } from '@mui/material'

type Props = {}

const SignIn: React.FC<Props> = () => {
  const { signInWithEmail } = useFirebase()
  const navigate = useNavigate()
  const { state } = useLocation()
  const { handleSubmit, control } = useForm<SignInFields>({
    defaultValues: { email: '', password: '' }
  })

  const onSubmit: SubmitHandler<SignInFields> = async data => {
    try {
      await signInWithEmail(data.email, data.password)
      // const res = await signIn(data.email, data.password)
      // console.log({ res })
      navigate(state?.path || '/')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        spacing={1}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        container
      >
        <TextField fieldName='email' label='Email' control={control} />
        <TextField
          fieldName='password'
          label='Password'
          // textFieldProps={{ type: "password" }}
          type='password'
          control={control}
        />
        <input type='submit' />
      </Grid>
    </form>
  )
}

export default SignIn
