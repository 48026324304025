import * as React from 'react'
import { Button, CircularProgress, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import EventCard from 'Components/EventCard'
import { useAxios } from 'Hooks'
import { BasicEvent } from 'Types'

type Props = {}

const styles = {
  container: {
    marginTop: '2em',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  innerContainer: {
    flexDirection: 'row'
  },
  link: {
    textDecoration: 'none',
    color: 'black'
  }
}

const Events: React.FC<Props> = () => {
  const { get } = useAxios()
  const [events, setEvents] = React.useState<BasicEvent[]>()
  const [update, setUpdate] = React.useState(false)

  React.useEffect(() => {
    if (!events || update) {
      console.log('getting events')
      get<BasicEvent[]>('admin/events')
        .then(({ data }) => setEvents(data))
        .catch(e => console.log({ e }))
      setUpdate(false)
    }
  }, [events, get, update])

  return (
    <Grid container spacing={2} sx={styles.container}>
      <Grid item>
        <Button variant='contained'>
          <Link to='./new' style={styles.link}>
            Add New Event
          </Link>
        </Button>
      </Grid>
      <Grid item container sx={styles.innerContainer} columnSpacing={2}>
        {events ? (
          events.map(event => <EventCard key={event.urlName} event={event} updateEvents={setUpdate} />)
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  )
}

export default Events
