import * as React from 'react'
import { Controller } from 'react-hook-form'

import UploadComponent from 'Components/Upload'
import FileUpload from 'Components/Upload/basic'
import { UploadFormProps } from 'Types'

export const FormUpload: React.FC<UploadFormProps> = ({
  fieldName,
  label,
  control,
  controllerProps,
  multiple,
  maxFileSize
}) => {
  return (
    <Controller
      name={fieldName}
      {...controllerProps}
      render={({ field }) => (
        <UploadComponent
          label={label}
          multiple={multiple}
          maxFileSize={maxFileSize}
          // Not sure if this will work correctly
          updateFilesCb={field.onChange}
        />
      )}
      control={control}
    />
  )
}

export const BasicFormUpload: React.FC<UploadFormProps> = ({
  fieldName,
  label,
  control,
  controllerProps,
  multiple,
  maxFileSize
}) => {
  return (
    <Controller
      name={fieldName}
      {...controllerProps}
      render={({ field: { value, onChange }, formState: { errors } }) => {
        const fieldError = errors[fieldName]

        return (
          <>
            <FileUpload
              label={label}
              multiple={multiple}
              maxFileSize={maxFileSize}
              value={value}
              // Not sure if this will work correctly
              updateFilesCb={onChange}
              error={fieldError && true}
            />
            {fieldError && <p>{fieldError.message}</p>}
          </>
        )
      }}
      control={control}
    />
  )
}
