import * as React from 'react'

import { Controller } from 'react-hook-form'
import { SketchPicker } from 'react-color'

import gridWrapper from 'Utils/GridWrapper'
import { FormProps } from 'Types'
import { Typography, Box } from '@mui/material'

const ColorPickerField: React.FC<FormProps> = ({ fieldName, control, label, controllerProps }) => {
  return (
    <Controller
      name={fieldName}
      {...controllerProps}
      render={({ field }) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h5'>{label}</Typography>
          <SketchPicker
            color={field.value}
            onChangeComplete={color => {
              field.onChange(color.hex)
            }}
          />
        </Box>
      )}
      control={control}
    />
  )
}

export const ColorPicker = ({
  disableGrid,
  disableSpacer,
  gridProps,
  spacerProps,
  ...props
}: FormProps) => {
  return gridWrapper<FormProps>({
    disableGrid,
    disableSpacer,
    Component: ColorPickerField,
    props,
    gridProps,
    spacerProps,
    key: props.fieldName
  })
}

export default ColorPicker
